import React, { useEffect, useState } from "react";
import { MapPin } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";
import { Link, useNavigate } from "react-router-dom";
import { useFETCH, usePOST } from "../Tools/APIs";
import Loader from "../components/Loader";
import axios from "axios";
import { content, language } from "../translation/Translate";
import { toast } from "sonner";

function Checkout() {
  const { data, isLoading } = useFETCH("locations", true);
  const [selectedLocation, setSelectedLocation] = useState();
  const { handleSubmit, successfulPost, loading, errors, setFormData } =
    usePOST({});
  const navigate = useNavigate();
  const checkout = () => {
    handleSubmit(`orders`, false, true, false);
  };

  useEffect(() => {
    if (selectedLocation) setFormData({ location_id: selectedLocation });
  }, [selectedLocation]);

  useEffect(() => {
    if (loading) {
      toast.loading(language === "ar" ? "يتم الطلب" : "Ordering", {
        id: "loading",
      });
    }
  }, [loading]);

  useEffect(() => {
    if (successfulPost) {
      toast.dismiss("loading");
      toast.success(content.cart.checkout.sent);
      navigate("/products");
    }
  }, [successfulPost]);

  useEffect(() => {
    if (errors) {
      toast.dismiss("loading");
    }
  }, [errors]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className=" space-y-6 md:space-y-12">
      <div className="space-y-4">
        <p className="font-bold text-lg">{content.cart.checkout.title}:</p>
        <div className="space-y-4 p-4">
          {data?.data?.length > 0 ? (
            data?.data?.map((location) => (
              <Location
                key={location.id}
                id={location.id}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
                lat={location.latitude}
                lng={location.longitude}
              />
            ))
          ) : (
            <div className="h-[60vh] col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 w-full flex justify-center items-center text-center">
              <Link to={"/profile"} className="cursor-pointer p-4 rounded-md text-lg text-white bg-amber-500 transition-colors border border-amber-500 hover:bg-white hover:text-amber-500">
                {content.profile.add_location}
              </Link>
            </div>
          )}
        </div>
        {data?.data?.length > 0 && (
          <Link to={"/profile"} className="cursor-pointer text-amber-500">
            {content.profile.add_location}
          </Link>
        )}
      </div>
      {selectedLocation && <CheckoutDialog action={checkout} />}
    </div>
  );
}

function Location({ lat, lng, selectedLocation, setSelectedLocation, id }) {
  const [address, setAddress] = useState("");

  const getAddress = async () => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=18&addressdetails=1&accept-language=${language}`
      );
      const data = response.data;
      setAddress(data.display_name || "Unknown");
    } catch (error) {
      console.error(error);
      setAddress("Unknown");
    }
  };

  useEffect(() => {
    getAddress();
  }, []);

  return (
    <div
      onClick={() => setSelectedLocation(id)}
      className={`flex items-center cursor-pointer w-full md:w-2/3 gap-4 font-semibold px-4 py-8 rounded ${
        selectedLocation === id ? "bg-gray-200" : "bg-white hover:bg-gray-100"
      } shadow-sm rounded transition-colors`}
    >
      <div className="bg-amber-500 rounded-full p-1">
        <MapPin className="text-white" />
      </div>
      {address ? (
        <p className="">{address}</p>
      ) : (
        <div className="animate-pulse bg-gray-100 rounded-lg w-full h-6 mb-2"></div>
      )}
    </div>
  );
}

function CheckoutDialog({ action }) {
  return (
    <Dialog>
      <DialogTrigger className="w-full md:w-1/2">
        <button
          className={`bg-amber-500 text-white w-full h-full p-2 border border-amber-500 rounded-md hover:text-amber-500 hover:bg-white transition`}
        >
          {content.cart.checkout.buy}
        </button>
      </DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle>
            {language === "ar" ? "هل انت متأكد من الشراء؟" : "Are you sure?"}
          </DialogTitle>
        </DialogHeader>
        <div className="flex w-full gap-4 items-center justify-center">
          <button
            onClick={action}
            className={`bg-amber-500 text-white w-full p-2 border border-amber-500 rounded-md hover:text-amber-500 hover:bg-white transition`}
          >
            {language === "en" ? "Buy" : "شراء"}
          </button>
          <button
            onClick={() => document.getElementById("close-trigger")?.click()}
            className="p-2 w-full border border-amber-500 rounded-md bg-white text-amber-500"
          >
            {language === "en" ? "Cancel" : "الغاء"}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default Checkout;
