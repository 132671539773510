import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import { fileUrl, useDelete, usePOST } from "../../Tools/APIs";
import { language } from "../../translation/Translate";
import { toast } from "sonner";
import { Link } from "react-router-dom";
import DeleteDialog from "../DeleteDialog";
import { useUser } from "../../lib/store/user";
import { Input } from "../ui/input";
import { Button } from "../ui/button";

function CartCard({ cartItem, setCart }) {
  const { deleteItem, isLoading, isError, successfulDelete } = useDelete();
  const [count, setCount] = useState(cartItem?.count);
  const { handleSubmit, successfulPost, dataPlayer, loading, errors, setSuccessfulPost } = usePOST(
    {}
  );
  const { setCartCount, cartCount } = useUser();
  const removeProduct = () => {
    document.getElementById("close-trigger")?.click();
    deleteItem(`cart/items/${cartItem.product.id}`);
  };

  useEffect(() => {
    if (isLoading) toast.loading("يتم الإزالة", { id: "loading" });
  }, [isLoading]);

  console.log(count);

  useEffect(() => {
    if (errors?.length > 0) {
      toast.dismiss("loading");
      errors.map((er) => toast.error(er));
    }
  }, [errors]);

  useEffect(() => {
    if (successfulDelete) {
      toast.dismiss("loading");
      setCartCount(cartCount - 1);
      setCart((prev) => prev.filter((item) => item.id !== cartItem.id));
      toast.success("تم الإزالة");
    }
  }, [successfulDelete]);

  useEffect(() => {
    if (dataPlayer?.data) {
      setCart((prev) =>
        prev.map((item) => {
          if (item.id === cartItem.id) return dataPlayer.data.data;
          return item;
        })
      );
    }
  }, [dataPlayer]);

  useEffect(() => {
    if (successfulPost) {
      toast.dismiss("loading");
      toast.success(language === "ar" ? "تم التعديل" : "Updated successfully");
      setSuccessfulPost(false);
    }
  }, [successfulPost]);

  useEffect(() => {
    if (loading) {
      toast.loading("loading", { id: "loading" });
    }
  }, [loading]);

  useEffect(() => {
    if (isError) {
      toast.error("خطأ في الإزالة");
      toast.dismiss("loading");
    }
  }, [isError]);

  const updateCount = () => {
    handleSubmit("cart/items/update", false, true, false, {
      count,
      product_id: cartItem.product.id,
      _method: "put",
    });
  };

  return (
    <div
      to={`/products/preview/${cartItem.product.id}/${cartItem.product.brand.id}`}
      className="rounded-lg flex flex-col cursor-pointer justify-between gap-2 shadow-md hover:shadow-xl transition-shadow text-center"
    >
      <div className="relative">
        <Link
          to={`/products/preview/${cartItem.product.id}/${cartItem.product.brand.id}`}
        >
          <img
            src={fileUrl + cartItem.product.image}
            alt={"product image"}
            className="rounded-lg aspect-[6/4] shadow-sm w-full object-cover object-center"
          />
        </Link>
        <div className="absolute top-2 left-2">
          <DeleteDialog action={removeProduct} />
        </div>
      </div>
      <div className="p-2 space-y-1">
        <p className="font-bold break-words">{cartItem.product.name}</p>
        <p className="px-2 text-sm break-words">
          {parse(cartItem.product.description || "")}
        </p>
      </div>
      <div className="pb-2 flex justify-center items-center gap-2 text-sm">
        <p className="font-semibold">
          {language === "ar" ? "العدد:" : "count:"}
        </p>
        <Input
          onChange={(e) => setCount(e.currentTarget.value)}
          className="w-20"
          type="number"
          value={count}
        />
      </div>
      {cartItem.count !== count && (
        <Button
          onClick={updateCount}
          className="border mb-2 rounded-md w-fit mx-auto p-1"
        >
          {language === "ar" ? "حفظ" : "save"}
        </Button>
      )}
    </div>
  );
}

export default CartCard;
