import React from "react";
import { fileUrl } from "../../Tools/APIs";

export default function EmployeeCard({ employee }) {
  return (
    <div className="px-4 py-2 h-full">
      <div className="rounded-md flex flex-col justify-between gap-2 shadow-md text-center">
        <div>
          <img
            src={fileUrl + employee.image}
            alt={"employee image"}
            className="rounded-lg aspect-[6/4] shadow-sm w-full object-cover object-center"
          />
        </div>
        <p className="font-bold break-words">{employee.name}</p>
        <p className="text-sm text-gray-700 break-words pb-2">{employee.job}</p>
      </div>
    </div>
  );
}
