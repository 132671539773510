import React, { useContext, useEffect, useState } from "react";
import { Button } from "../ui/button";
import { Heart } from "lucide-react";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { direction, language } from "../../translation/Translate";
import { fileUrl, useDelete, useFETCH } from "../../Tools/APIs";
import { toast } from "sonner";
import { useUser } from "../../lib/store/user";
import { UserContext } from "../../App";

function ProductCard({ product, setFavorites }) {
  const user = useContext(UserContext);
  const setFavorite = useFETCH(false, true);
  const { setFavoritesCount, favoritesCount } = useUser();
  const deleteFavorite = useDelete();
  const navigate = useNavigate();
  const [favorite, setIsfavorite] = useState(product.is_favorite);

  const handleFavorite = () => {
    setIsfavorite((prev) => !prev);
    if (!favorite) {
      setFavorite.fetch(`favorites/${product.id}`);
    } else {
      deleteFavorite.deleteItem(`favorites/${product.id}`);
    }
  };

  useEffect(() => {
    if (setFavorite.data?.code === 200) {
      setFavoritesCount(favoritesCount + 1);
      toast.dismiss("loading");
      toast.success("Done");
    }
  }, [setFavorite.data]);

  useEffect(() => {
    if (deleteFavorite.successfulDelete) {
      toast.dismiss("loading");
      setFavoritesCount(favoritesCount - 1);
      toast.success("Done");
      setFavorites((prev) => prev.filter((f) => f.id !== product.id));
    }
  }, [deleteFavorite.successfulDelete]);

  useEffect(() => {
    if (setFavorite.isLoading) toast.loading("loading", { id: "loading" });
  }, [setFavorite.isLoading]);

  useEffect(() => {
    if (deleteFavorite.isLoading) toast.loading("loading", { id: "loading" });
  }, [deleteFavorite.isLoading]);

  return (
    <div className="rounded-lg group h-full cursor-pointer flex flex-col justify-between gap-4 shadow-md hover:shadow-xl transition-shadow text-center">
      <div className="relative">
        <img
          src={fileUrl + product.image}
          alt={"product image"}
          className="rounded-lg shadow-sm aspect-[6/4] w-full object-cover object-center"
        />
        {user && (
          <Heart
            onClick={() => handleFavorite()}
            className={`absolute top-3 right-3 md:hover:text-amber-500 md:hover:fill-amber-500 transition ${
              favorite
                ? "fill-amber-500 text-amber-500"
                : "text-amber-300 fill-white"
            }`}
          />
        )}
      </div>
      <div dir={direction} className="flex justify-between items-center px-2">
        <p className="font-bold break-words">{product.name}</p>
        <p className="text-amber-500 text-sm flex gap-1">
          <span>{product.price}</span>
          <span className="font-bold">{language === "ar" ? "د.ع" : "IQD"}</span>
        </p>
      </div>
      <div className="px-2 text-sm break-words">
        {parse(product.description || "")}
      </div>
      <Button
        onClick={() =>
          navigate(`/products/preview/${product.id}/${product.brand.id}`)
        }
        className="bg-orange-100 text-amber-500 md:group-hover:bg-amber-500 md:group-hover:text-white transition-all font-bold w-full p-4 rounded-b-md"
      >
        {language === "ar" ? "عرض المنتج" : "View Product"}
      </Button>
    </div>
  );
}

export default ProductCard;
