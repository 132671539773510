import React from "react";
import { Button } from "../ui/button";
import { Link, useLocation } from "react-router-dom";
import { language } from "../../translation/Translate";
import { fileUrl } from "../../Tools/APIs";
import parse from "html-react-parser";

function BranchCard({ cardDetails }) {
  const location = useLocation();

  return (
    <div className="rounded-lg group cursor-pointer flex flex-col justify-between gap-4 shadow-md hover:shadow-xl transition-shadow text-center">
      <div>
        <img
          src={fileUrl + cardDetails?.images?.at(0)?.url}
          alt={"product image"}
          className="rounded-lg shadow-sm aspect-[6/4] w-full object-cover object-center"
        />
      </div>
      <div className="p-2">
        <p className="font-bold break-words">{cardDetails.name}</p>
        <p className="px-2 text-sm break-words"> {parse(cardDetails.description || "")}</p>
      </div>
      <Link to={`/branches/preview/${cardDetails.id}`}>
        <Button className="bg-orange-100 text-amber-500 group-hover:bg-amber-500 group-hover:text-white transition-all font-bold w-full p-4 rounded-b-md">
          {language === "ar" ? "عرض الفرع" : "View Branch"}
        </Button>
      </Link>
    </div>
  );
}

export default BranchCard;
