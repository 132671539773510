import React, { useEffect } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { content, language } from "../../translation/Translate";
import { usePOST } from "../../Tools/APIs";
import { Button } from "../ui/button";
import { toast } from "sonner";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const LocationMarker = ({ setLatLng }) => {
  const [position, setPosition] = React.useState(null);

  useMapEvents({
    click(e) {
      setPosition(e.latlng);
      setLatLng(e.latlng);
    },
  });

  return position != null && <Marker position={position} />;
};

const FixedMarker = ({ position }) => <Marker position={position} />;

const Location = ({ defaultLat, defaultLng, fixedLat, fixedLng, fetch }) => {
  const { handleSubmit, formData, setFormData, successfulPost, loading, errors, dataPlayer } = usePOST({});

  useEffect(() => {
    if(loading) {
      toast.loading(language === "ar" ? "يتم الإضافة" : "Adding Location", {id: "loading"})
    }
  }, [loading])

  useEffect(() => {
    if(errors) {
      toast.dismiss("loading");
    }
  }, [errors])

  useEffect(() => {
    if(successfulPost) {
      fetch("locations")
      toast.dismiss("loading")
      toast.success(language === "ar" ? "تمت الإضافة" : "Added successfully")
    }
  }, [successfulPost])

  const onLocationSelect = ({ lat, lng }) => {
    setFormData({ ...formData, latitude: lat, longitude: lng });
  };

  const submit = (e) => {
    e.preventDefault();
    handleSubmit(`locations`, false, true, false);
  };

  return (
    <>
      <MapContainer
        center={fixedLat && fixedLng ? [fixedLat, fixedLng] : [defaultLat, defaultLng]}
        zoom={13}
        className="z-40"
        style={{ height: fixedLat && fixedLng ? "30vh" : "50vh", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {fixedLat && fixedLng ? (
          <FixedMarker position={[fixedLat, fixedLng]} />
        ) : (
          <LocationMarker setLatLng={onLocationSelect} />
        )}
      </MapContainer>
      {!fixedLat && !fixedLng && (
        <Button
          onClick={submit}
          className={`bg-amber-500 text-white w-1/2 h-full p-2 border border-amber-500 rounded-md hover:text-amber-500 hover:bg-white transition`}
        >
          {content.profile.add_location}
        </Button>
      )}
    </>
  );
};

export default Location;
