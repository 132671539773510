import React from "react";

export default function CardSkeleton() {
  return (
    <div className="rounded-lg group cursor-pointer space-y-4 shadow-md text-center animate-pulse">
      <div className="h-60 bg-gray-200 rounded-lg"></div>{" "}
      {/* Placeholder for image */}
      <div className="h-6 bg-gray-200 rounded w-3/4 mx-auto"></div>{" "}
      {/* Placeholder for name */}
      <div className="h-10 bg-gray-200 rounded w-full mx-auto"></div>{" "}
      {/* Placeholder for button */}
    </div>
  );
}
