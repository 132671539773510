import React, { useContext, useEffect, useState } from "react";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { content, language } from "../translation/Translate";
import Location from "../components/profile/Location";
import ChangePassword from "../components/profile/ChangePassword";
import { fileUrl, useDelete, useFETCH, usePOST } from "../Tools/APIs/index";
import { UserContext } from "../App";
import { toast } from "sonner";
import Loader from "../components/Loader";
import DeleteDialog from "../components/DeleteDialog";
import LocationAddress from "../components/LocationAddress";
import AddImage from "../components/AddImage";

function Profile() {
  const user = useContext(UserContext);
  const [changePassword, setChangePassword] = useState(false);
  const [addLocation, setAddLocation] = useState(false);
  const [deletedLocation, setDeletedLocation] = useState();
  const [locations, setLocations] = useState([]);
  const { data, isLoading, fetch } = useFETCH("locations", true);
  const { handleSubmit, formData, handleChangeInput, successfulPost, viewFile } = usePOST(
    {
      _method: "put"
    }
  );

  const deleteLocation = useDelete();

  const validate = () => {
    const keysToCheck = Object.keys(formData).filter(
      (key) => key !== "_method"
    );
    return keysToCheck.some((key) => !!formData[key]);
  };

  useEffect(() => {
    if (data) setLocations(data?.data);
  }, [data]);

  useEffect(() => {
    if (deleteLocation.successfulDelete) {
      document.getElementById("close-trigger")?.click();
      toast.dismiss("loading");
      toast.success(language === "ar" ? "تم الحذف" : "Deleted successfully");
      setLocations((prev) => prev.filter((l) => l.id !== deletedLocation));
      setDeletedLocation(undefined);
    }
  }, [deleteLocation.successfulDelete]);

  useEffect(() => {
    if (deleteLocation.isLoading) {
      toast.loading(language === "ar" ? "يتم الحذف" : "Deleting", {
        id: "loading",
      });
    }
  }, [deleteLocation.isLoading]);

  useEffect(() => {
    if (deleteLocation.isError) {
      document.getElementById("close-trigger")?.click();
      toast.dismiss("loading");
      toast.error(
        language === "en"
          ? "Error in deleting try again"
          : "خطأ في الحذف أعد المحاولة"
      );
    }
  }, [deleteLocation.isError]);

  useEffect(() => {
    if (successfulPost) {
      toast.success(language === "en" ? "done" : "تم");
    }
  }, [successfulPost]);

  const updateProfile = (e) => {
    e.preventDefault();
    if (validate()) handleSubmit("profile/update?method=put", false, true, false);
  };

  const deleteAction = (id) => {
    setDeletedLocation(id);
    deleteLocation.deleteItem(`locations/${id}`);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="px-4 md:px-12 py-8 space-y-8">
      <h2 className="text-center font-bold text-2xl md:text-3xl">
        {content.profile.title}
      </h2>
      <form className="flex flex-col gap-8">
        <label className="text-amber-500">{content.profile.info}</label>
        <AddImage
          inputName="image"
          initialImage={fileUrl +  user?.image}
          handleChangeInput={handleChangeInput}
          viewImage={viewFile}
        />
        <div className="flex gap-4 w-full items-center">
          <Input
            className="h-full p-3 bg-gray-100"
            type="name"
            name="username"
            placeholder={content.profile.userName}
            defaultValue={user?.username}
            onChange={handleChangeInput}
          />
          <Input
            className="h-full p-3 bg-gray-100"
            type="name"
            name="first_name"
            placeholder={content.profile.first_name}
            defaultValue={user?.first_name}
            onChange={handleChangeInput}
          />
        </div>
        <div className="flex gap-4 w-full items-center">
          <Input
            className="h-full p-3 bg-gray-100"
            type="name"
            name="last_name"
            placeholder={content.profile.last_name}
            defaultValue={user?.last_name}
            onChange={handleChangeInput}
          />
          <Input
            className="h-full p-3 bg-gray-100"
            name="phone_number"
            type="text"
            placeholder={content.profile.phone}
            defaultValue={user?.phone_number}
            onChange={handleChangeInput}
          />
        </div>

        <Button
          onClick={updateProfile}
          className={`bg-amber-500 text-white w-1/2 h-full p-2 border border-amber-500 rounded-md hover:text-amber-500 hover:bg-white transition`}
        >
          {content.profile.submit}
        </Button>

        {locations?.map((location) => (
          <div
            key={location.id}
            className="flex max-sm:flex-col gap-4 items-center shadow-md border rounded-md p-2 md:p-4"
          >
            <LocationAddress lat={location.latitude} lng={location.longitude} />
            <DeleteDialog action={() => deleteAction(location.id)} />
          </div>
        ))}
      </form>
      <form className="flex flex-col gap-8">
        <label
          onClick={() => setAddLocation((prev) => !prev)}
          className="text-amber-500 underline cursor-pointer"
        >
          {content.profile.add_location + "+"}
        </label>
        {addLocation && (
          <Location defaultLat={33.5138} defaultLng={36.2765} fetch={fetch} />
        )}
      </form>
      <form className="flex flex-col gap-8">
        <Button
          onClick={(e) => {
            setChangePassword((prev) => !prev);
            e.preventDefault();
          }}
          className={`${
            changePassword
              ? "bg-white text-amber-500"
              : "bg-amber-500 text-white"
          } w-1/2 h-full p-2 border border-amber-500 rounded-md hover:text-amber-500 hover:bg-white transition`}
        >
          {content.profile.change_password_btn}
        </Button>
        {changePassword && <ChangePassword />}
      </form>
    </div>
  );
}

export default Profile;
