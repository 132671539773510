import React, { useEffect, useState } from "react";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { content, language } from "../translation/Translate";
import { usePOST } from "../Tools/APIs";
import { toast } from "sonner";
import LoadingButton from "../components/ui/LoadingButton";
import ConfirmAccount from "./ConfirmAccount";

function Signup() {
  const [email, setEmail] = useState("");
  const [validErrors, setValidErrors] = useState({});

  const {
    handleChangeInput,
    handleSubmit,
    successfulPost,
    setFormData,
    formData,
    loading,
    errors,
    dataPlayer,
  } = usePOST();

  useEffect(() => {
    if (successfulPost) {
      toast.dismiss("loading");
    }
  }, [successfulPost]);

  useEffect(() => {
    if (loading) {
      toast.loading(language === "ar" ? "يتم التحميل" : "loading", {
        id: "loading",
      });
    }
  }, [loading]);

  useEffect(() => {
    if (errors) {
      toast.dismiss("loading");
      Object.entries(errors)?.map((err) => toast.error(err.at(0)));
    }
  }, [errors]);

  const validateInputs = () => {
    const newErrors = {};
    if (!email) newErrors.email = "Email is required.";
    if (!formData?.username) newErrors.username = "Username is required.";
    if (!formData?.first_name) newErrors.first_name = "First name is required.";
    if (!formData?.last_name) newErrors.last_name = "Last name is required.";
    if (!formData?.phone_number)
      newErrors.phone_number = "Phone number is required.";
    if (!formData?.password) newErrors.password = "Password is required.";
    if (!formData?.confirm_password)
      newErrors.confirm_password = "Confirm Password is required.";
    if (formData?.confirm_password !== formData?.password)
      newErrors.confirm_password = "Password not correct";
    return newErrors;
  };

  const submit = async (e) => {
    e.preventDefault();
    const newErrors = validateInputs();
    setValidErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      await handleSubmit("send/verification-code", false, true, false, {
        email,
      });
    }
  };

  return (
    <div className="w-full flex max-md:flex-col-reverse justify-center items-center px-4 md:px-12 py-8">
      <div className="md:basis-1/2 h-full w-full bg-white shadow-md rounded-md py-12 px-4 sm:p-8 md:p-12 flex flex-col justify-center gap-8">
        {!successfulPost ? (
          <>
            <h2 className="text-center font-bold text-2xl md:text-3xl">
              {content.signup.title}
            </h2>
            <form className="flex flex-col gap-8 justify-center">
              <div>
                <Input
                  required
                  type="email"
                  placeholder={content.signup.email}
                  className="p-4 h-full bg-gray-100 rounded-lg"
                  name="email"
                  onChange={(e) => {
                    handleChangeInput(e);
                    setEmail(e.currentTarget.value);
                  }}
                  value={formData?.email}
                />
                {validErrors.email && (
                  <span className="text-red-500 text-sm">
                    {validErrors.email}
                  </span>
                )}
              </div>
              <div>
                <Input
                  required
                  type="text"
                  placeholder={content.signup.userName}
                  className="p-4 h-full bg-gray-100 rounded-lg"
                  name="username"
                  value={formData?.username}
                  onChange={(e) => handleChangeInput(e)}
                />
                {validErrors.username && (
                  <span className="text-red-500 text-sm">
                    {validErrors.username}
                  </span>
                )}
              </div>
              <div>
                <Input
                  required
                  type="text"
                  value={formData?.first_name}
                  placeholder={content.signup.first_name}
                  className="p-4 h-full bg-gray-100 rounded-lg"
                  name="first_name"
                  onChange={(e) => handleChangeInput(e)}
                />
                {validErrors.first_name && (
                  <span className="text-red-500 text-sm">
                    {validErrors.first_name}
                  </span>
                )}
              </div>
              <div>
                <Input
                  required
                  value={formData?.last_name}
                  type="text"
                  placeholder={content.signup.last_name}
                  className="p-4 h-full bg-gray-100 rounded-lg"
                  name="last_name"
                  onChange={(e) => handleChangeInput(e)}
                />
                {validErrors.last_name && (
                  <span className="text-red-500 text-sm">
                    {validErrors.last_name}
                  </span>
                )}
              </div>
              <div>
                <Input
                  required
                  type="text"
                  value={formData?.phone}
                  placeholder={content.signup.phone}
                  className="p-4 h-full bg-gray-100 rounded-lg"
                  name="phone_number"
                  onChange={(e) => handleChangeInput(e)}
                />
                {validErrors.phone_number && (
                  <span className="text-red-500 text-sm">
                    {validErrors.phone_number}
                  </span>
                )}
              </div>
              <div>
                <Input
                  required
                  value={formData?.password}
                  type="password"
                  placeholder={content.signup.password}
                  className="p-4 h-full bg-gray-100 rounded-lg"
                  name="password"
                  onChange={(e) => handleChangeInput(e)}
                />
                {validErrors.password && (
                  <span className="text-red-500 text-sm">
                    {validErrors.password}
                  </span>
                )}
              </div>
              <div>
                <Input
                  required
                  value={formData?.confirm_password}
                  onChange={(e) => handleChangeInput(e)}
                  type="password"
                  name="confirm_password"
                  placeholder={content.signup.confirm}
                  className="p-4 h-full bg-gray-100 rounded-lg"
                />
                {validErrors.confirm_password && (
                  <span className="text-red-500 text-sm">
                    {validErrors.confirm_password}
                  </span>
                )}
              </div>
              {loading ? (
                <LoadingButton />
              ) : (
                <Button
                  className="bg-amber-400 rounded-lg text-white p-4"
                  onClick={(e) => submit(e)}
                >
                  {content.signup.title}
                </Button>
              )}
            </form>
            <p className="text-center text-sm">
              {content.signup.haveAccount + " "}
              <Link
                to="/login"
                className="underline text-amber-500 cursor-pointer"
              >
                {content.signup.login}
              </Link>
            </p>
          </>
        ) : (
          <ConfirmAccount formData={formData} />
        )}
      </div>
      <div className="md:basis-1/2 max-md:hidden w-1/2 select-none flex justify-center">
        <img className="w-2/3" src="/images/logo.png" />
      </div>
    </div>
  );
}

export default Signup;
