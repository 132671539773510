import React, { useEffect, useState } from "react";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Link, useNavigate } from "react-router-dom";
import { content, language } from "../translation/Translate";
import { usePOST } from "../Tools/APIs";
import { toast } from "sonner";
import { useCookies } from "react-cookie";
import LoadingButton from "../components/ui/LoadingButton";

function Login() {
  const navigate = useNavigate();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const {
    handleSubmit,
    successfulPost,
    errors,
    setFormData,
    dataPlayer,
    formData,
    loading,
  } = usePOST();
  const [cookies, setCookie] = useCookies([
    "token",
    {
      doNotParse: true,
    },
  ]);

  useEffect(() => {
    if (loading) {
      toast.loading(language === "ar" ? "يتم تسجيل الدخول" : "Login in", {
        id: "loading",
      });
    }
  }, [loading]);

  useEffect(() => {
    setFormData({ username, password, token: cookies.token });
  }, [username, password]);

  useEffect(() => {
    if (errors.length > 0) {
      toast.dismiss("loading");
      errors.map((e) => toast.error(e));
    }
  }, [errors]);

  useEffect(() => {
    if (successfulPost) {
      toast.dismiss("loading");
      navigate("/");
      window.location.reload();
    }
  }, [successfulPost]);

  useEffect(() => {
    if (dataPlayer) {
      setCookie("token", dataPlayer.data?.data?.user?.token, { path: "/" });
    }
  }, [dataPlayer]);

  const login = (e) => {
    e.preventDefault();
    if(formData.password && formData.username)
      handleSubmit("login", false, true, false);
    else 
      toast.error(language === "ar" ? "قم بتعبئة الحقول" : "fill out the fields")
  };

  return (
    <div className="w-full flex max-md:flex-col-reverse justify-center items-center px-4 md:px-12 md:py-8">
      <div className="md:basis-1/2 h-full w-full bg-white shadow-md rounded-md py-12 px-4 sm:p-8 md:p-12 flex flex-col gap-8 justify-between">
        <h2 className="text-center font-bold text-2xl md:text-3xl">
          {content.login.title}
        </h2>
        <form className="flex flex-col gap-8 justify-center">
          <Input
            required
            type="text"
            name="username"
            placeholder={content.login.name}
            className="p-4 h-full bg-gray-100 rounded-lg"
            onChange={(e) => setUserName(e.currentTarget.value)}
            value={username}
          />
          <Input
            required
            type="password"
            name="password"
            placeholder={content.login.password}
            className="p-4 h-full bg-gray-100 rounded-lg"
            onChange={(e) => setPassword(e.currentTarget.value)}
            value={password}
          />
          <Link
            to="/login/change-password"
            className="self-start underline cursor-pointer text-sm text-gray-400"
          >
            {content.login.forgetPassword}
          </Link>
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              onClick={(e) => login(e)}
              className="bg-amber-400 rounded-lg text-white p-4"
            >
              {content.login.title}
            </Button>
          )}
        </form>
        <p className="text-center text-sm">
          {content.login.dontHaveAccount + " "}
          <Link
            to={"/signup"}
            className="underline text-amber-500 cursor-pointer"
          >
            {content.login.createAccount}
          </Link>
        </p>
      </div>
      <div className="md:basis-1/2 max-md:hidden select-none w-1/2 flex justify-center">
        <img className="w-2/3" src="/images/logo.png" />
      </div>
    </div>
  );
}

export default Login;
