import React, { useContext, useEffect, useRef, useState } from "react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Send } from "lucide-react";
import { useMessages } from "../../lib/store/messages";
import { usePOST } from "../../Tools/APIs";
import { UserContext } from "../../App";

function ChatInput() {
  const user = useContext(UserContext);
  const { addMessage, updateMessage } = useMessages();
  const inputRef = useRef(null);
  const [messageQueue, setMessageQueue] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const { handleSubmit, successfulPost, dataPlayer, errors } = usePOST({});

  const sendMessage = () => {
    const value = inputRef.current.value;
    if (value) {
      const tempMessage = {
        id: Date.now(),
        sender_id: user?.id,
        content: value,
        loading: true,
      };
      addMessage(tempMessage);
      setMessageQueue((prevQueue) => [...prevQueue, tempMessage]); 
      inputRef.current.value = "";
    }
  };

  const processQueue = () => {
    if (messageQueue.length === 0) {
      return; 
    }
    const messageToSend = messageQueue[0]; 
    setIsSending(true); 
    handleSubmit(`chat/users/admin/messages`, false, true, false, {
      content: messageToSend.content,
    });
    setMessageQueue((prevQueue) => prevQueue.slice(1));
  };

  useEffect(() => {
    if (successfulPost && dataPlayer) {
      updateMessage(dataPlayer.data?.data); 
      setIsSending(false);
    }
  }, [successfulPost, dataPlayer]);

  useEffect(() => {
    if (!isSending) {
      processQueue();
    }
  }, [messageQueue, isSending]);

  return (
    <div className="flex gap-2 w-full">
      <Input
        ref={inputRef}
        name="content"
        type="text"
        className="h-full p-2 md:p-3 bg-white "
        placeholder="اكتب الرسالة"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            sendMessage();
          }
        }}
      />
      <Button
        onClick={sendMessage}
        className="rounded-md p-3 md:p-4 bg-amber-500"
        type="submit"
      >
        <Send className="text-white" />
      </Button>
    </div>
  );
}

export default ChatInput;
