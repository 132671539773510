import React, { useEffect } from "react";
import { Input } from "../ui/input.jsx";
import { Button } from "../ui/button.jsx";
import { Textarea } from "../ui/textarea.jsx";
import { content, language } from "../../translation/Translate.js";
import { usePOST } from "../../Tools/APIs/index.js";
import { toast } from "sonner";
import LoadingButton from "../ui/LoadingButton.jsx";

function ContactUsForm() {
  const {
    handleSubmit,
    handleChangeInput,
    formData,
    setFormData,
    successfulPost,
    errors,
    loading,
  } = usePOST({});

  const submit = (e) => {
    e.preventDefault();
    if (
      formData?.email &&
      formData?.name &&
      formData?.message &&
      formData?.phone
    )
      handleSubmit("contact-messages", false, true, false);
    else {
      toast.warning(language === "en" ? "fill out the fields first" : "قم بتعبئة جميع الحقول");
    }
  };

  useEffect(() => {
    if (successfulPost) {
      toast.dismiss("loading");
      toast.success(language === "ar" ? "تم الإرسال" : "Message Sent");
    }
  }, [successfulPost]);

  useEffect(() => {
    if (loading) {
      toast.loading(language === "ar" ? "يتم الإرسال" : "Sending Message", {
        id: "loading",
      });
    }
  }, [loading]);

  useEffect(() => {
    if (errors) {
      toast.dismiss("loading");
    }
  }, [errors]);

  return (
    <form className="border-4 relative shadow-md z-0 border-amber-500 rounded-[40px] px-8 sm:px-20 lg:px-24">
      <div className="space-y-8 relative z-10 scale-110 bg-gray-50 mt-20">
        <div className="space-y-2">
          <Input
            className="p-5 bg-gray-100"
            type="name"
            id="name"
            name="name"
            onChange={(e) => handleChangeInput(e)}
            placeholder={content.home.name_placeholder}
          />
          {errors.name &&
            errors.name.map((error, index) => (
              <p key={index} className="text-red-600 w-fit text-sm" dir="auto">
                {error}
              </p>
            ))}
        </div>
        <div className="space-y-2">
          <Input
            className="p-5 bg-gray-100"
            type="email"
            id="email"
            name="email"
            onChange={(e) => handleChangeInput(e)}
            placeholder={content.home.email_placeholder}
          />
          {errors.email &&
            errors.email.map((error, index) => (
              <p key={index} className="text-red-600 w-fit text-sm" dir="auto">
                {error}
              </p>
            ))}
        </div>
        <div className="space-y-2">
          <Input
            className="p-5 bg-gray-100"
            type="text"
            id="phone"
            name="phone"
            onChange={(e) => handleChangeInput(e)}
            placeholder={content.home.phone_placeholder}
          />
          {errors.phone &&
            errors.phone.map((error, index) => (
              <p key={index} className="text-red-600 w-fit text-sm" dir="auto">
                {error}
              </p>
            ))}
        </div>
        <div className="space-y-2">
          <Textarea
            className="p-5 bg-gray-100"
            name="message"
            id="message"
            onChange={(e) => handleChangeInput(e)}
            placeholder={content.home.message_placeholder}
          />
          {errors.message &&
            errors.message.map((error, index) => (
              <p key={index} className="text-red-600 w-fit text-sm" dir="auto">
                {error}
              </p>
            ))}
        </div>
        {loading ? (
         <LoadingButton />
        ) : (
          <Button
            type="submit"
            onClick={submit}
            className="bg-amber-500 mx-auto border border-amber-500 text-white hover:bg-white hover:text-amber-500 p-2 px-4 rounded-md"
          >
            {content.home.submit_text}
          </Button>
        )}
      </div>
    </form>
  );
}

export default ContactUsForm;
