import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Facebook, Instagram, Twitter } from "lucide-react";
import { content, language } from "../translation/Translate";

function Footer({ infos }) {
  return (
    <footer className="py-8 px-4 md:px-12 bg-gray-800 text-white">
      <div className="flex max-md:flex-col justify-center items-center gap-8 md:gap-12">
        <div className="flex flex-col items-center max-md:flex-row gap-8 md:basis-1/2">
          <img
            src="/images/logo.png"
            alt="logo"
            className="w-24 h-fit lg:w-32"
          />
          <p className="max-md:text-sm">{infos?.footer.about}</p>
        </div>
        <div className="space-y-8 max-md:w-full md:basis-1/2 flex flex-col justify-between text-sm">
          <div className="flex justify-between max-md:flex-wrap">
            <div className="flex flex-col gap-2 px-2">
              <p className="font-bold text-amber-400">
                {content.footer.main_links}
              </p>
              {content.nav.map((link, index) => {
                if (index <= 2)
                  return (
                    <Link
                      className="hover:underline text-gray-400 hover:text-white"
                      to={link.to}
                    >
                      {link.name}
                    </Link>
                  );
              })}
            </div>
            <div className="flex flex-col gap-2 px-2">
              <p className="font-bold text-amber-400">
                {content.footer.main_links}
              </p>
              {content.nav.map((link, index) => {
                if (index > 2 && index < 5)
                  return (
                    <Link
                      className="hover:underline text-gray-400 hover:text-white"
                      to={link.to}
                    >
                      {link.name}
                    </Link>
                  );
              })}
            </div>
            <div className="flex flex-col gap-2 px-2">
              <p className="font-bold text-amber-400">{content.footer.help}</p>
              <Link
                className="hover:underline text-gray-400 hover:text-white"
                to="/contactUs"
              >
                {content.footer.help}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center gap-3 items-center mt-8">
        <div className="flex items-center gap-4">
          <div className="cursor-pointer hover:text-white">
            <Instagram className="w-5 h-5 text-gray-300 md:w-6 md:h-6" />
          </div>
          <div className="cursor-pointer hover:text-white">
            <Facebook className="w-5 h-5 text-gray-300 md:w-6 md:h-6" />
          </div>
          <div className="cursor-pointer hover:text-white">
            <Twitter className="w-5 h-5 text-gray-300 md:w-6 md:h-6" />
          </div>
        </div>
        <p className="text-xs text-gray-400">{content.footer.copyright}</p>
        <p className="text-xs text-gray-400">
          {language === "ar"
            ? " تم التطوير بواسطة فريق icr 2024"
            : "Developed by icr team 2024"}
        </p>
      </div>
    </footer>
  );
}
export default Footer;
