import React from "react";
import ImagesSlider from "../components/ImagesSlider";
import { useParams } from "react-router-dom";
import { fileUrl, useFETCH } from "../Tools/APIs";
import parse from "html-react-parser";
import VideoSkeleton from "../components/skeletons/VideoSkeleton";
import ImageSkeleton from "../components/skeletons/ImageSkeleton";
import EmployeeCard from "../components/cards/EmployeeCard";
import CardsSlider from "../components/CardsSlider";
import { content, language } from "../translation/Translate";
import { Mail, MapPin, Phone } from "lucide-react";
import { AvatarIcon } from "@radix-ui/react-icons";
import CardSkeleton from "../components/skeletons/CardSkeleton";

function Branch() {
  const { id } = useParams();
  const { data, isLoading } = useFETCH(`branches/${id}`);

  return (
    <div className="px-4 md:px-12 py-8 space-y-8">
      <h2 className="text-center font-bold text-3xl">{data?.data?.name}</h2>
      {isLoading ? (
        <VideoSkeleton />
      ) : (
        <div className="relative">
          <video
            key={fileUrl + data?.data?.video}
            className="w-full md:w-2/3 mx-auto"
            controls
          >
            <source src={fileUrl + data?.data?.video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}

      {isLoading ? (
        <div>
          <div className="animate-pulse bg-gray-100 rounded-lg w-full h-6 mb-2"></div>
          <div className="animate-pulse bg-gray-100 rounded-lg w-full h-6 mb-2"></div>
          <div className="animate-pulse bg-gray-100 rounded-lg w-full h-6 mb-2"></div>
        </div>
      ) : (
        <p className="font-semibold p-4">
          {parse(data?.data?.description || "")}
        </p>
      )}
      {isLoading ? (
        <ImageSkeleton />
      ) : (
        <ImagesSlider images={data?.data?.images} height={true} />
      )}
      <div
        dir="ltr"
        className="sm:flex w-full py-8 justify-center items-center"
      >
        <div className="basis-1/3 flex px-2 max-md:text-sm flex-col justify-center items-centers gap-3 text-center max-sm:border-2 max-sm:p-3 max-sm:my-2 max-sm:rounded-md border-gray-300  space-y-2">
          <p className="flex items-center justify-center gap-2">
            <Mail className="rounded-full  bg-black p-1 text-white" />
            <span>{content.home.email}</span>
          </p>
          <a
            className="text-gray-600 text-sm"
            href={`mailto:${data?.data?.email}`}
          >
            {data?.data?.email}
          </a>
        </div>
        <div className="basis-1/3 flex px-2 max-md:text-sm flex-col justify-center items-centers gap-3 text-center max-sm:border-2 max-sm:p-3 max-sm:my-2 max-sm:rounded-md border-gray-300 space-y-2 sm:border-l-4 border-gray-300">
          <p className="flex items-center justify-center gap-2">
            <Phone className="rounded-full bg-black p-1 text-white" />
            <span>{content.home.phone}</span>
          </p>
          <a
            className="text-gray-600 text-sm"
            href={`tel:${data?.data?.email}`}
          >
            {data?.data?.phone}
          </a>
        </div>
        <div className="basis-1/3 flex px-2 max-md:text-sm flex-col justify-center items-centers gap-3 text-center max-sm:border-2 max-sm:p-3 max-sm:my-2 max-sm:rounded-md border-gray-300 space-y-2 sm:border-l-4 border-gray-300">
          <p className="flex items-center justify-center gap-2">
            <MapPin className="rounded-full bg-black p-1 text-white" />
            <span>{language === "ar" ? "الموقع" : "Location"}</span>
          </p>
          <p className="text-gray-600 text-sm">{data?.data?.location}</p>
        </div>
        <div className="basis-1/3 flex px-2 max-md:text-sm flex-col justify-center items-centers gap-3 text-center max-sm:border-2 max-sm:p-3 max-sm:my-2 max-sm:rounded-md border-gray-300 sm:border-l-4 space-y-2">
          <p className="flex items-center justify-center gap-2">
            <AvatarIcon className="rounded-full bg-black p-1 w-6 h-6 text-white" />
            <span>
              {language === "ar" ? "عدد الموظفين" : "Number Of Employees"}
            </span>
          </p>
          <p className="text-gray-600 text-sm">
            {data?.data?.employees?.length}
          </p>
        </div>
      </div>
      <div>
        <h4 className="text-center font-bold text-3xl">
          {language === "ar" ? "الموظفين" : "Employees"}
        </h4>
        <CardsSlider
          cards={
            isLoading
              ? Array.from({ length: 6 }).map(() => {
                  return (
                    <div className="px-4 h-full">
                      <CardSkeleton />
                    </div>
                  );
                })
              : data?.data?.employees?.map((employee) => (
                  <EmployeeCard employee={employee} />
                ))
          }
        />
      </div>
    </div>
  );
}

export default Branch;
