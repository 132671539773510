import React from "react";
import { useFETCH } from "../Tools/APIs/index";
import Loader from "../components/Loader";
import { language } from "../translation/Translate";
import Order from "../components/cards/OrderCard";

function Orders() {
  const { data, isLoading } = useFETCH(`orders`, true);
  return (
    <div className="px-2 sm:px-4 h-full md:px-8 py-8 space-y-8">
      <h2 className="text-center font-bold text-2xl md:text-3xl text-gray-800">
        {language === "ar" ? "الطلبات" : "Orders"}
      </h2>
      <div className="grid gap-4 py-4 w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-items-center">
        {!isLoading ? (
          data?.data?.data?.length > 0 ? (
            data?.data?.data?.map((order) => (
              <Order key={order?.id} order={order} />
            ))
          ) : (
            <div className="h-[60vh] col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 w-full flex justify-center items-center text-center">
              <p
                dir="auto"
                className="w-full text-xl font-bold text-center text-gray-600"
              >
                 {language === "en" ? "No orders." : "لا يوجد طلبات." }
              </p>
            </div>
          )
        ) : (
          <div className="col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
}



export default Orders;
