import Message from "./Message";
import { useMessages } from "../../lib/store/messages";
import { Loader } from "lucide-react";
import { useEffect, useRef } from "react";

export default function ListMessages({ page, setPage, last_page, isLoading }) {
  const { messages } = useMessages();
  const messageListRef = useRef();

  const handleScroll = () => {
    if (messageListRef.current) {
      const { scrollTop } = messageListRef.current;
      if (scrollTop === 0 && page < last_page && !isLoading) {
        setPage((prev) => prev + 1);
      }
    }
  };

  useEffect(() => {
    const messageList = messageListRef.current;
    if (messageList) {
      messageList.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (messageList) {
        messageList.removeEventListener("scroll", handleScroll);
      }
    };
  }, [page, isLoading, last_page]);

  return (
    <div
      ref={messageListRef}
      id="messageList"
      className="flex-1 flex flex-col gap-4 py-4 overflow-y-auto"
    >
      {isLoading && (
        <div className="w-full flex justify-center">
          <Loader className="animate-spin text-blue-800" />
        </div>
      )}
      <div className="flex flex-col-reverse gap-4 py-4">
        {messages?.map((message, index) => (
          <Message
            key={index}
            message={message}
            isSameUser={
              index !== messages.length
                ? message.sender_id
                  ? message.sender_id === messages.at(index + 1)?.sender_id
                  : !message.sender_id && !messages.at(index + 1)?.sender_id
                : false
            }
          />
        ))}
      </div>
    </div>
  );
}
