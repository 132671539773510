import React from "react";
import BranchCard from "../components/cards/BranchCard";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { content, language } from "../translation/Translate";
import { useFETCH } from "../Tools/APIs";
import CardSkeleton from "../components/skeletons/CardSkeleton";

function Branches() {
  const { data, isLoading } = useFETCH("branches");

  const location = useLocation();

  return (
    <div className="px-2 sm:px-4 md:px-8 py-8 space-y-8">
      {location.pathname.includes("/preview") ? (
        <Outlet />
      ) : (
        <>
          <h2 className="text-center font-bold text-2xl md:text-3xl">
            {content.branches.title}
          </h2>
          <div className="grid gap-4 py-4 w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-6">
            {!isLoading ? (
              data?.data?.data?.length > 0 ? (
                data?.data?.data?.map((branch) => {
                  return <BranchCard cardDetails={branch} />;
                })
              ) : (
                <div className="h-[60vh] col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 w-full flex justify-center items-center text-center">
                  <p
                    dir="auto"
                    className="w-full text-xl font-bold text-center"
                  >
                    {language === "en"
                      ? "No branches available"
                      : "لا يوجد فروع."}
                    .
                  </p>
                </div>
              )
            ) : (
              <>
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Branches;
