import React, { useEffect, useState } from "react";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "../components/ui/inputOtp";
import { Button } from "../components/ui/button";
import { useNavigate } from "react-router-dom";
import { usePOST } from "../Tools/APIs";
import { toast } from "sonner";
import ResendCode from "../components/ResendCode";
import { content, language } from "../translation/Translate";
import { useCookies } from "react-cookie";

function ConfirmAccount({ formData }) {
  const [cookies, setCookie, removeCookie] = useCookies([
    "token",
    {
      doNotParse: true,
    },
  ]);
  const { handleSubmit, dataPlayer, errors, successfulPost, loading } =
    usePOST();
  const [code, setCode] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (dataPlayer?.data?.data?.user) {
      setCookie("token", dataPlayer?.data?.data?.user?.token);
    }
  }, [dataPlayer]);

  useEffect(() => {
    if (loading) {
      toast.loading("loading", { id: "loading" });
    }
  }, [loading]);

  useEffect(() => {
    if (errors?.length > 0) {
      toast.dismiss("loading");
      errors?.map((e) => toast.error(e));
    }
  }, [errors]);

  useEffect(() => {
    if (successfulPost) {
      navigate("/login");
      toast.dismiss("loading");
    }
  }, [successfulPost]);

  const submit = () => {
    if (code.length === 4)
      handleSubmit("register", false, true, false, {
        ...formData,
        verification_code: code,
      });
  };

  return (
    <>
      <h2 className="text-center font-bold text-2xl md:text-3xl">
        {content.confirm_account.title}
      </h2>
      <p className="text-center">{content.confirm_account.banner}</p>
      <div dir="ltr" className="w-full flex justify-center items-center">
        <InputOTP onChange={(value) => setCode(value)} maxLength={6}>
          <InputOTPGroup>
            <InputOTPSlot index={0} />
            <InputOTPSlot index={1} />
            <InputOTPSlot index={2} />
            <InputOTPSlot index={3} />
          </InputOTPGroup>
        </InputOTP>
      </div>
      <ResendCode email={formData?.email} />
      <Button
        onClick={submit}
        className="bg-amber-400 rounded-lg text-white p-4"
      >
        {language === "ar" ? "تأكيد" : "Confirm"}
      </Button>
    </>
  );
}

export default ConfirmAccount;
