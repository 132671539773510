import React, { useEffect, useState } from "react";
import CartCard from "../components/cards/CartCard";
import { Link, Outlet, useLocation } from "react-router-dom";
import { language, content } from "../translation/Translate";
import { useFETCH } from "../Tools/APIs";
import CardSkeleton from "../components/skeletons/CardSkeleton";
import { Button } from "../components/ui/button";

function Cart() {
  const { data, isLoading } = useFETCH("cart/items", true);
  const [cart, setCart] = useState();
  const location = useLocation();

  useEffect(() => {
    if (data) setCart(data?.data);
  }, [data]);

  return (
    <div className="px-2 relative w-full sm:px-4 md:px-8 py-8 space-y-8">
      <h2 className="text-center font-bold text-3xl">{content.cart.title}</h2>
      {location.pathname === "/cart" ? (
        <>
          <div className="grid gap-4 py-4 w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-6">
            {!isLoading ? (
              cart?.length > 0 ? (
                cart?.map((cartItem) => {
                  return <CartCard setCart={setCart} cartItem={cartItem} />;
                })
              ) : (
                <div className="h-[60vh] col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 w-full flex justify-center items-center text-center">
                  <p
                    dir="auto"
                    className="w-full text-xl font-bold text-center"
                  >
                    {language === "en" ? "Cart is empty." : "السلة فارغة"}
                  </p>
                </div>
              )
            ) : (
              <>
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
                <CardSkeleton />
              </>
            )}
          </div>
          {cart?.length > 0 && (
            <div className="w-full flex justify-center">
              <Link
                to={"/cart/checkout"}
                className={`text-center bg-amber-500 text-white w-1/3 md:w-1/4  py-2 px-4 border border-amber-500 rounded-md hover:text-amber-500 hover:bg-white transition`}
              >
                {language === "ar" ? "التالي" : "Next"}
              </Link>
            </div>
          )}
        </>
      ) : (
        <Outlet />
      )}
    </div>
  );
}

export default Cart;
