import React, { useEffect, useState } from "react";
import { Button } from "../components/ui/button";
import LoadingButton from "../components/ui/LoadingButton";
import { Input } from "../components/ui/input";
import { content, language } from "../translation/Translate";
import { usePOST } from "../Tools/APIs";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "../components/ui/inputOtp";
import { toast } from "sonner";

function ForgetPassword() {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [show, setShow] = useState(false);
  const [code, setCode] = useState();

  const { handleSubmit, successfulPost, loading, errors } = usePOST();

  useEffect(() => {
    if (successfulPost) {
      if(!show)
      setShow((prev) => !prev);
      toast.dismiss("loading")
    }
  }, [successfulPost]);

  useEffect(() => {
    if(errors?.length > 0) {
      toast.dismiss("loading");
      errors.map((err) => toast.error(err))
    }
  }, [errors])

  useEffect(() => {
    if(loading) 
      toast.loading(language === "ar" ? "يتم التحميل" : "Loading", {id: "loading"})
  }, [loading])

  const sendVerificationCode = async () => {
    if (email) {
      await handleSubmit("send/verification-code", false, true, false, {
        email,
      });
    }
  };

  const resetPassword = async () => {
    if (password && confirmPassword) {
      await handleSubmit("reset-password", false, true, false, {
        password,
        email,
        password_confirmation: confirmPassword,
        verification_code: code,
      });
    }
  };

  const submit = (e) => {
    e.preventDefault();
    if (show) {
      if (password && confirmPassword && email) resetPassword();
      else
        toast.error(
          language === "ar" ? "قم بتعبئة الحقول" : "Fill out the fields"
        );
    } else {
      if (email) sendVerificationCode();
      else
        toast.error(
          language === "ar" ? "قم بتعبئة الحقول" : "Fill out the fields"
        );
    }
  };

  return (
    <div className="w-full flex max-md:flex-col-reverse justify-center items-center px-4 md:px-12 md:py-8">
      <div className="md:basis-1/2 h-full w-full bg-white shadow-md rounded-md py-12 px-4 sm:p-8 md:p-12 flex flex-col gap-8 justify-between">
        <h2 className="text-center font-bold text-2xl md:text-3xl">
          {content.forget_password.title}
        </h2>
        <form className="flex flex-col gap-8 justify-center">
          {show ? (
            <>
              <Input
                required
                type="password"
                name="password"
                placeholder={content.forget_password.new_password}
                className="p-4 h-full bg-gray-100 rounded-lg"
                onChange={(e) => setPassword(e.currentTarget.value)}
                value={password}
              />
              <Input
                required
                type="password"
                name="password_confirmation"
                placeholder={content.forget_password.confirm_password}
                className="p-4 h-full bg-gray-100 rounded-lg"
                onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                value={confirmPassword}
              />
              <label>
                <p>{content.forget_password.code}</p>
                <InputOTP onChange={(value) => setCode(value)} maxLength={4}>
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                    <InputOTPSlot index={3} />
                  </InputOTPGroup>
                </InputOTP>
              </label>
            </>
          ) : (
            <Input
              required
              type="email"
              name="email"
              placeholder={content.forget_password.email}
              className="p-4 h-full bg-gray-100 rounded-lg"
              onChange={(e) => setEmail(e.currentTarget.value)}
              value={email}
            />
          )}
          {loading ? <LoadingButton /> : <Button
            onClick={submit}
            className="bg-amber-400 rounded-lg text-white p-4"
          >
            {show
              ? content.forget_password.submit
              : content.forget_password.send_code}
          </Button>}
        </form>
      </div>
      <div className="md:basis-1/2 max-md:hidden select-none w-1/2 flex justify-center">
        <img className="w-2/3" src="/images/logo.png" />
      </div>
    </div>
  );
}

export default ForgetPassword;
