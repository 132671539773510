import React from "react";
import OfferCard from "../components/cards/OfferCard";
import { content, language } from "../translation/Translate";
import { useFETCH } from "../Tools/APIs";
import CardSkeleton from "../components/skeletons/CardSkeleton";

function Offers() {
  const { data, isLoading } = useFETCH("offers");
  return (
    <div className="px-2 sm:px-4 md:px-8 py-8 space-y-8">
      <h2 className="text-center font-bold text-2xl md:text-3xl">
        {content.offers.title}
      </h2>
      <div className="grid gap-4 py-4 w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-6">
        {!isLoading ? (
          data?.data?.length > 0 ? (
            data?.data?.map((offer) => {
              return <OfferCard cardDetails={offer} />;
            })
          ) : (
            <div className="h-[60vh] col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 w-full flex justify-center items-center text-center">
              <p dir="auto" className="w-full text-xl font-bold text-center">
              {language === "en" ? "No offers available." : "لا يوجد عروض."}
              </p>
            </div>
          )
        ) : (
          <>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </>
        )}
      </div>
    </div>
  );
}

export default Offers;
