import React, { useContext, useState } from "react";
import { Filter, Search } from "lucide-react";
import { Button } from "../ui/button";
import { Arrow } from "../ui/svgs";
import { Input } from "../ui/input";
import { content, language } from "../../translation/Translate";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { useNavigate } from "react-router-dom";

function SearchBar({ searchInfos }) {
  const [filterBy, setFilterBy] = useState("all");
  const [search, setSearch] = useState("");
  const [name, setName] = useState("");
  const [startPrice, setStartPrice] = useState("");
  const [endPrice, setEndPrice] = useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    if (filterBy === "all" && search) {
      navigate(`/products?search=${search}`);
    } else if (filterBy === "name" && name) {
      navigate(`/products?search=${name}&sort_by=name`);
    } else {
      if (startPrice && endPrice) {
        navigate(
          `/products?start_price=${startPrice}&end_price=${endPrice}&sort_by=price`
        );
      } else if (startPrice) {
        navigate(`/products?start_price=${startPrice}&sort_by=price`);
      } else if (endPrice) {
        navigate(`/products?end_price=${endPrice}&sort_by=price`);
      }
    }
  };

  const handleSearchChange = (e) => {
    if (filterBy === "all") {
      setSearch(e.currentTarget.value);
    } else if (filterBy === "name") {
      setName(e.currentTarget.value);
    } else {
    }
  };

  return (
    <div dir="ltr" className="w-full flex items-center justify-center py-8">
      <div className="w-full md:w-2/3 flex items-center justify-center gap-8">
        <Arrow style="max-sm:hidden" />
        <div className="flex flex-col gap-8 items-center">
          <p className="md:text-xl font-bold text-center">{searchInfos}</p>
          <div className="w-full flex-1 flex max-sm:flex-col-reverse items-center gap-2">
            <Select onValueChange={(value) => setFilterBy(value)}>
              <SelectTrigger className="w-[120px] h-full shadow-lg flex gap-1 max-md:text-sm justify-center  items-center border rounded-lg p-3 sm:p-4">
                <span className="max-sm:hidden">{filterBy}</span>
                <Filter className="text-yellow-500" />
              </SelectTrigger>
              <SelectContent className="shadow-lg bg-white">
                <SelectItem value="all">
                  {language === "en" ? "all" : "الكل"}
                </SelectItem>
                <SelectItem value="price">
                  {language === "en" ? "price" : "السعر"}
                </SelectItem>
                <SelectItem value="name">
                  {language === "en" ? "name" : "الاسم"}
                </SelectItem>
              </SelectContent>
            </Select>
            <div className="flex flex-1 w-full h-full group-focus:ring-1 ring-yellow-500 items-center shadow-lg p-1 sm:pr-8 rounded-lg">
              {filterBy === "price" ? (
                <>
                  <Input
                    type="number"
                    minValue={0}
                    dir="auto"
                    className="h-full p-3 sm:p-4"
                    onChange={(e) => setStartPrice(e.currentTarget.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                    placeholder={
                      language === "en" ? "Start Price" : "السعر المبدئي"
                    }
                  />
                  <Input
                    type="number"
                    minValue={0}
                    dir="auto"
                    className="h-full p-3 sm:p-4"
                    onChange={(e) => setEndPrice(e.currentTarget.value)}
                    placeholder={
                      language === "en" ? "Start Price" : "السعر النهائي"
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                </>
              ) : (
                <Input
                  dir="auto"
                  type="search"
                  onChange={handleSearchChange}
                  className="flex-1 w-full h-full p-3 sm:p-4 group focus-visible:ring-0 focus-visible:border-0 border-0 rounded-lg"
                  placeholder={content.home.search_placeholder}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              )}
              <Search
                id="search"
                onClick={handleSearch}
                className="text-gray-500 w-12 cursor-pointer"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
