import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../components/ui/button";
import parse from "html-react-parser";
import { fileUrl, useDelete, useFETCH, usePOST } from "../Tools/APIs";
import { Input } from "../components/ui/input";
import { toast } from "sonner";
import Loader from "../components/Loader";
import { language } from "../translation/Translate";
import { UserContext } from "../App";
import LoadingButton from "../components/ui/LoadingButton";
import { Heart } from "lucide-react";
import { useUser } from "../lib/store/user";

function Product() {
  const user = useContext(UserContext);
  const { setCartCount, setFavoritesCount, favoritesCount, cartCount } =
    useUser();
  const { productId, brandId } = useParams();
  const { data, isLoading } = useFETCH(`${brandId}/products/${productId}`);
  const setFavorite = useFETCH(false, true);
  const [favorite, setIsfavorite] = useState(data?.data?.is_favorite);
  const deleteFavorite = useDelete();
  const {
    handleSubmit,
    handleChangeInput,
    successfulPost,
    loading,
    errors,
    dataPlayer,
    formData,
  } = usePOST({
    product_id: productId,
    count: 1,
  });

  const handleFavorite = () => {
    setIsfavorite((prev) => !prev);
    if (!favorite) {
      setFavorite.fetch(`favorites/${productId}`);
    } else {
      deleteFavorite.deleteItem(`favorites/${productId}`);
    }
  };

  useEffect(() => {
    if (setFavorite.data?.code === 200) {
      setFavoritesCount(favoritesCount + 1);
      toast.dismiss("loading");
      toast.success("Done");
    }
  }, [setFavorite.data]);

  useEffect(() => {
    if (deleteFavorite.successfulDelete) {
      toast.dismiss("loading");
      setFavoritesCount(favoritesCount - 1);
      toast.success("Done");
    }
  }, [deleteFavorite.successfulDelete]);

  useEffect(() => {
    if (setFavorite.isLoading) toast.loading("loading", { id: "loading" });
  }, [setFavorite.isLoading]);

  useEffect(() => {
    if (deleteFavorite.isLoading) toast.loading("loading", { id: "loading" });
  }, [deleteFavorite.isLoading]);

  useEffect(() => {
    if (successfulPost || dataPlayer?.code === 200) {
      setCartCount(cartCount + 1);
      toast.dismiss("loading");
      toast.success(
        language === "ar" ? "تمت الإضافة إلى السلة" : "Product added to cart"
      );
    }
  }, [successfulPost, dataPlayer]);

  useEffect(() => {
    if (Object?.entries(errors)?.length > 0) {
      toast.dismiss("loading");
      toast.error(errors.message);
    }
  }, [errors]);

  useEffect(() => {
    if (loading) {
      toast.loading(
        language === "en" ? "Adding To Cart" : "يتم الإضافة للسلة",
        { id: "loading" }
      );
    }
  }, [loading]);

  useEffect(() => {
    if (dataPlayer?.data) {
      setCartCount(dataPlayer.data?.data.cart_products_count);
    }
  }, [dataPlayer]);

  const addToCart = () => {
    if (!loading)
      if (user) {
        if (formData?.count && formData.count > 0)
          handleSubmit(`cart/items`, false, true, false);
        else
          toast.error(
            language === "ar"
              ? "العدد يجب ان يكون أكثر من الصفر"
              : "count must be greater than zero"
          );
      } else
        toast.error(
          language === "ar" ? "قم بتسجيل الدخول أولاً" : "Login First"
        );
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="px-2 flex flex-col md:flex-row w-full gap-8 sm:px-4 md:px-8 py-8">
      <div className="md:w-1/2 relative flex justify-center items-center">
        <img
          src={fileUrl + data?.data?.image}
          alt="Product"
          className="rounded-lg shadow-lg transition-transform duration-300 hover:scale-105"
        />
        {user && (
          <Heart
            onClick={() => handleFavorite()}
            className={`absolute top-3 cursor-pointer right-3 md:hover:text-amber-500 md:hover:fill-amber-500 transition ${
              favorite
                ? "fill-amber-500 text-amber-500"
                : "text-amber-300 fill-white"
            }`}
          />
        )}
      </div>
      <div className="md:w-1/2 flex flex-col justify-between gap-6 bg-white rounded-lg shadow-lg p-6">
        <div className="space-y-4">
          <h3 className="font-bold text-3xl text-gray-800">
            {data?.data?.name}
          </h3>
          <p className="text-gray-600 text-lg">
            {parse(data?.data?.description || "")}
          </p>
          <p className="font-bold text-2xl text-green-600">
            {data?.data?.price} {language === "ar" ? "د.ع" : "IQD"}
          </p>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-between">
          <Input
            type="number"
            min={1}
            defaultValue={1}
            name="count"
            onChange={handleChangeInput}
            className="border rounded-lg p-3 w-24 md:w-32 focus:outline-none focus:ring-2 focus:ring-orange-400"
          />
          {loading ? (
            <LoadingButton />
          ) : (
            <Button
              onClick={addToCart}
              className="bg-orange-100 text-amber-500 md:hover:bg-amber-500 md:hover:text-white transition-all font-bold w-full md:w-auto p-4 rounded-md mt-4 md:mt-0"
            >
              {language === "ar" ? "إضافة للسلة" : "Add To Cart"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Product;
