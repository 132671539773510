import React from "react";
import ImagesSlider from "../components/ImagesSlider";
import { MapPin, Mail, Phone } from "lucide-react";
import ContactUsComponent from "../components/contactUs/ContactUsComponent";
import { content } from "../translation/Translate";
import { useFETCH } from "../Tools/APIs";

function ContactUs() {
  const { data, isLoading } = useFETCH("infos", false);

  return (
    <div className="px-4 md:px-12 py-8 space-y-8">
      <h2 className="text-center font-bold text-2xl md:text-3xl">
        {content.contact_us.title}
      </h2>
      <p className="text-center md:text-xl font-bold">
        {content.contact_us.description}
      </p>
      <div className="py-8">
        <BranchesInfo />
      </div>

      <ContactUsComponent infos={data} />
    </div>
  );
}

function BranchesInfo() {
  const { data, isLoading } = useFETCH(`branches`);

  return (
    <div
      className={
        "w-full flex flex-wrap gap-6 max-sm:text-sm justify-center items-center "
      }
    >
      {!isLoading
        ? data?.data?.data?.map((branch) => (
            <div className="w-full sm:basis-1/3 max-sm:rounded-lg p-2 border-4 border-gray-200 rounded-lg space-y-4 flex flex-col justify-cetner items-center">
              <p className="font-bold text-lg md:text-xl">{branch.name}</p>
              <p className="flex items-center gap-2">
                <Mail className="rounded-full  bg-black p-1 text-white" />
                <span>{branch.email}</span>
              </p>
              <p className="flex items-center gap-2">
                <Phone className="rounded-full  bg-black p-1 text-white" />
                <span>{branch.phone}</span>
              </p>
              <p className="flex items-center gap-2">
                <MapPin className="rounded-full  bg-black p-1 text-white" />
                <span>{branch.location}</span>
              </p>
            </div>
          ))
        : Array.from({ length: 3 }).map((_, index) => (
            <div
              key={index}
              className="w-full sm:basis-1/3 max-sm:rounded-lg p-2 border-4 border-gray-200 rounded-lg space-y-4 flex flex-col justify-cetner items-center"
            >
              <div className="animate-pulse bg-gray-100 rounded-lg w-full h-8 mb-2"></div>
              <div className="animate-pulse bg-gray-100 rounded-lg w-full h-6 mb-2"></div>
              <div className="animate-pulse bg-gray-100 rounded-lg w-full h-6 mb-2"></div>
              <div className="animate-pulse bg-gray-100 rounded-lg w-full h-6 mb-2"></div>
            </div>
          ))}
    </div>
  );
}

export default ContactUs;
