import React, { useEffect, useState } from "react";

import ProductCard from "../components/cards/ProductCard";
import { content, language } from "../translation/Translate";
import { useFETCH } from "../Tools/APIs";
import CardSkeleton from "../components/skeletons/CardSkeleton";

function Favorites() {
  const { data, isLoading } = useFETCH("favorites", true);
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {if(data) setFavorites(data?.data)}, [data])

  return (
    <div className="px-2 sm:px-4 md:px-8 py-8 space-y-8">
      <h2 className="text-center font-bold text-2xl md:text-3xl">
        {content.favorite.title}
      </h2>
      <div className="grid gap-4 py-4 w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-6">
        {!isLoading ? (
          favorites?.length > 0 ? (
            favorites?.map((product) => {
              return <ProductCard key={product.id} setFavorites={setFavorites} product={product} isFavorite={true} />;
            })
          ) : (
            <div className="h-[60vh] col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 w-full flex justify-center items-center text-center">
              <p dir="auto" className="w-full text-xl font-bold text-center">
                {language === "en" ? "Favorites is empty." : "المفضلة فارغة."}
              </p>
            </div>
          )
        ) : (
          <>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </>
        )}
      </div>
    </div>
  );
}

export default Favorites;
