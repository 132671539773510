import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { language } from "../translation/Translate";
import { fileUrl } from "../Tools/APIs";
import ImageSkeleton from "./skeletons/ImageSkeleton";

function ImagesSlider({ images, height }) {
  const settings = {
    infinite: images?.length > 1,
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    rtl: language === "ar",
  };

  return images?.length > 0 ? (
    <div className="slider-container py-4">
      <Slider {...settings}>
        {images?.map((image) => {
          return (
            <SliderItem
              key={image.id}
              image={image.file || image.url}
              height={height}
            />
          );
        })}
      </Slider>
    </div>
  ) : (
    <div>
      <ImageSkeleton />
    </div>
  );
}

function SliderItem({ image, height }) {
  return (
    <div className="w-full px-2 rounded-lg">
      <img
        src={fileUrl + image}
        alt="product image"
        className={`rounded-lg shadow-md ${height && "h-52"} ${
          height && "md:h-[500px]"
        } ${!height && "h-full"}  w-full object-cover`}
      />
    </div>
  );
}

export default ImagesSlider;
