import React from "react";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "./ui/select";
import { changeLanguage } from "../translation/Translate";
import { Globe } from "lucide-react";
function LanguageSelect() {
  let language = window.localStorage.getItem("language");

  return (
    <Select value={language} onValueChange={(value) => changeLanguage(value)}>
      <SelectTrigger className="w-16 flex items-center border rounded-md p-1 gap-1 select-none">
        <Globe className="w-4 shrink-0" />
        <SelectValue placeholder={language ? language : "en"} />
      </SelectTrigger>
      <SelectContent className="bg-white w-full cursor-pointer shadow-lg rounded">
        <SelectItem className="w-16 h-full cursor-pointer transition-all p-1" value="en">
          en
        </SelectItem>

        <SelectItem className="w-16 h-full cursor-pointer transition-all p-1" value="ar">
          ar
        </SelectItem>
      </SelectContent>
    </Select>
  );
}

export default LanguageSelect;
