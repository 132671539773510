import Translate from "./translate.json";

export let language = window.localStorage.getItem("language");

export let direction = language === "ar" ? "rtl" : "ltr";

export let content = {};

export const changeLanguage = (lang) => {
  window.localStorage.setItem("language", lang);
  window.location.reload(false);
};

if (language === "ar") {
  content = Translate.ar;
} else {
  content = Translate.en;
}