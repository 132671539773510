import { Menu } from "lucide-react";
import React, { useContext } from "react";
import { Drawer } from "vaul";
import { Link, useLocation } from "react-router-dom";
import { Button } from "./ui/button";
import { ShoppingCart, Heart } from "lucide-react";
import { Facebook, Instagram, Mail, Phone, Twitter } from "lucide-react";
import { content, language } from "../translation/Translate";
import { UserContext } from "../App";
import LanguageSelect from "./LanguageSelect";
import { useUser } from "../lib/store/user";

function NavDrawer() {
  const location = useLocation();
  const user = useContext(UserContext);
  const { cartCount, favoritesCount } = useUser();

  return (
    <div>
      <Drawer.Root
        direction={language === "ar" ? "left" : "right"}
        shouldScaleBackground
      >
        <Drawer.Trigger asChild className="lg:hidden">
          <button>
            <Menu className="w-7 h-7" />
          </button>
        </Drawer.Trigger>
        <Drawer.Portal>
          <Drawer.Overlay className="fixed inset-0 bg-black/40" />
          <Drawer.Content
            className={`bg-zinc-100 flex flex-col rounded-t-[10px] h-full overflow-x-hidden max-md:w-[80%] w-1/2 mt-24 fixed bottom-0 ${
              language === "ar" ? "left-0" : "right-0"
            }`}
          >
            <div className="p-4 bg-white rounded-t-[10px] overflow-y-auto flex-1 flex flex-col items-center gap-8">
              {content.nav.map((link) => {
                if (link.to === "/")
                  return (
                    <Link
                      className={`hover:text-yellow-400 transition-all ${
                        location.pathname === link.to ? "text-yellow-400" : ""
                      }`}
                      to={link.to}
                    >
                      {link.name}
                    </Link>
                  );
                else
                  return (
                    <Link
                      className={`hover:text-yellow-400 transition-all ${
                        location.pathname.includes(link.to)
                          ? "text-yellow-400"
                          : ""
                      }`}
                      to={link.to}
                    >
                      <Drawer.Close>{link.name}</Drawer.Close>
                    </Link>
                  );
              })}
              {user ? (
                <>
                  <Link
                    to="/cart"
                    className={`${
                      location.pathname.includes("/cart")
                        ? "bg-amber-400"
                        : "bg-white"
                    } group relative hover:bg-amber-400 p-2 cursor-pointer border border-amber-400 rounded transition-colors`}
                  >
                    <Drawer.Close>
                      <ShoppingCart
                        className={`${
                          location.pathname.includes("/cart")
                            ? "fill-white text-white"
                            : "fill-amber-400 text-amber-400"
                        } group-hover:fill-white group-hover:text-white transiton-colors`}
                      />
                    </Drawer.Close>
                    <div className="absolute -top-2 -right-2 flex justify-center items-center bg-amber-500 text-white text-sm rounded-full text-center w-5 h-5">
                      {cartCount}
                    </div>
                  </Link>
                  <Link
                    to="/favorites"
                    className={`${
                      location.pathname === "/favorites"
                        ? "bg-amber-400"
                        : "bg-white"
                    } group relative hover:bg-amber-400 cursor-pointer border border-amber-400 rounded transition-colors`}
                  >
                    <Drawer.Close className="p-2">
                      <Heart
                        className={`${
                          location.pathname === "/favorites"
                            ? "fill-white text-white"
                            : "fill-amber-400 text-amber-400"
                        } group-hover:fill-white group-hover:text-white transiton-colors`}
                      />
                    </Drawer.Close>
                    <div className="absolute -top-2 -right-2 flex justify-center items-center bg-amber-500 text-white text-sm rounded-full text-center w-5 h-5">
                      {favoritesCount}
                    </div>
                  </Link>
                </>
              ) : (
                <>
                  <Link className="" to="/login">
                    <Drawer.Close>
                      <Button className="p-2 bg-yellow-400 hover:bg-yellow-500 rounded-md text-white">
                        {content.login.title}
                      </Button>
                    </Drawer.Close>
                  </Link>
                  <Link className="" to="/signup">
                    <Drawer.Close>
                      <Button className="p-2 bg-yellow-400 hover:bg-yellow-500 rounded-md text-white">
                        {content.signup.title}
                      </Button>
                    </Drawer.Close>
                  </Link>
                </>
              )}
              <LanguageSelect />
              <div className="flex items-center gap-2">
                <Mail className="w-6 h-6" />
                <p>info@info.com</p>
              </div>
              <div className="flex items-center gap-2">
                <Phone className="w-6 h-6" />
                <p>+963-987654321</p>
              </div>
            </div>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    </div>
  );
}

export default NavDrawer;
