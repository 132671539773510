import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CircleArrowLeft } from "lucide-react";
import { language } from "../translation/Translate";
import { Link } from "react-router-dom";

function CardsSlider({ cards, title, link, slidesToShow }) {
  const settings = {
    className: "center",
    infinite: cards?.length > 1,
    dots: false,
    arrows: false,
    slidesToShow: slidesToShow ? slidesToShow : 4,
    slidesToScroll: 1,
    speed: 500,

    pauseOnHover: true,
    rtl: language === "en",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="space-y-6 py-8">
      {link && (
        <div className="flex justify-between items-center py-2">
          <div className="flex gap-2 items-center">
            <div className="w-1 h-12 rounded-sm bg-yellow-500"></div>
            <p className="font-bold text-lg md:text-2xl">{title}</p>
          </div>
          <Link to={link} className="flex items-center gap-2">
            <p className="underline cursor-pointer text-xs md:text-sm">
              {language === "ar" ? "عرض الكل" : "Show All"}
            </p>
            <CircleArrowLeft className="w-5 cursor-pointer text-amber-500" />
          </Link>
        </div>
      )}

      {cards?.length > 0 ? (
        <div className="slider-container">
          <Slider {...settings}>{cards}</Slider>
        </div>
      ) : (
        <div dir="auto" className="w-full flex items-center justify-center">
          {"No items."}
        </div>
      )}
    </div>
  );
}

export default CardsSlider;
