import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "./ui/button";
import { useUser } from "../lib/store/user";
import { Heart, ShoppingCart } from "lucide-react";
import UserDropDown from "./UserDropDown";
import LanguageSelect from "./LanguageSelect";
import { content, language } from "../translation/Translate";
import { UserContext } from "../App";

function NavBar() {
  const location = useLocation();
  const user = useContext(UserContext);
  const { cartCount, favoritesCount } = useUser();

  return (
    <div className="flex-1 max-lg:hidden flex justify-between items-center">
      {content.nav.map((link) => {
        if (link.to === "/")
          return (
            <Link
              className={`hover:text-yellow-400 transition-all ${
                location.pathname === link.to ? "text-yellow-400" : ""
              }`}
              to={link.to}
            >
              {link.name}
            </Link>
          );
        else
          return (
            <Link
              className={`hover:text-yellow-400 transition-all ${
                location.pathname.includes(link.to) ? "text-yellow-400" : ""
              }`}
              to={link.to}
            >
              {link.name}
            </Link>
          );
      })}

      <LanguageSelect />

      {user ? (
        <>
          <Link
            to="/cart"
            className={`${
              location.pathname.includes("/cart") ? "bg-amber-400" : "bg-white"
            } group relative hover:bg-amber-400 cursor-pointer border border-amber-400 rounded transition-colors`}
          >
            <div className="p-2">
              <ShoppingCart
                className={`${
                  location.pathname.includes("/cart")
                    ? "fill-white text-white"
                    : "fill-amber-400 text-amber-400"
                } group-hover:fill-white group-hover:text-white transiton-colors`}
              />
            </div>
            <div className="absolute -top-2 -right-2 flex justify-center items-center bg-amber-500 text-white text-sm rounded-full text-center w-5 h-5">
              {cartCount}
            </div>
          </Link>
          <Link
            to="/favorites"
            className={`${
              location.pathname === "/favorites" ? "bg-amber-400" : "bg-white"
            } group relative hover:bg-amber-400 cursor-pointer border border-amber-400 rounded transition-colors`}
          >
            <div className="p-2">
              <Heart
                className={`${
                  location.pathname === "/favorites"
                    ? "fill-white text-white"
                    : "fill-amber-400 text-amber-400"
                } group-hover:fill-white group-hover:text-white transiton-colors`}
              />
            </div>
            <div className="absolute -top-2 -right-2 flex justify-center items-center bg-amber-500 text-white text-sm rounded-full text-center w-5 h-5">
              {favoritesCount}
            </div>
          </Link>
          <UserDropDown />
        </>
      ) : (
        <>
          <Link className="" to="/login">
            <Button className="bg-yellow-400 p-2 hover:bg-yellow-500 rounded-md transition-colors text-white">
              {language === "ar" ? "تسجيل الدخول" : "Login"}
            </Button>
          </Link>
          <Link className="" to="/signup">
            <Button className="bg-yellow-400 p-2 hover:bg-yellow-500 rounded-md transition-colors text-white">
              {language === "ar" ? "إنشاء حساب" : "Signup"}
            </Button>
          </Link>
        </>
      )}
    </div>
  );
}

export default NavBar;
