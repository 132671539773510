import React, { useContext } from "react";
import { Facebook, Instagram, Mail, Phone, Twitter } from "lucide-react";
import { Pinterest } from "./ui/svgs";
import NavBar from "./NavBar";
import NavDrawer from "./NavDrawer";
import UserDropDown from "./UserDropDown";
import { UserContext } from "../App";
import { Link } from "react-router-dom";

function Header({ infos }) {
  return (
    <div>
      <div className="max-md:hidden flex justify-between text-white max-md:text-sm bg-slate-800 p-4">
        <div className="flex max-lg:text-sm items-center text-sm gap-4 md:gap-12">
          <div className="flex items-center gap-2">
            <Mail className="w-6 h-6" />
            <a href={`mailto:${infos?.contact_us?.email}`}>
              {infos?.contact_us?.email}
            </a>
          </div>
          <div className="flex items-center gap-2">
            <Phone className="w-6 h-6" />
            <a href={`tel:${infos?.contact_us?.email}`}>
              {infos?.contact_us?.phone1}
            </a>
          </div>
          <div className="flex items-center gap-2">
            <Phone className="w-6 h-6" />
            <a href={`tel:${infos?.contact_us?.email}`}>
              {infos?.contact_us?.phone2}
            </a>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <a target="_blank" href={infos?.footer?.instagram}>
            <Instagram className="w-6 h-6" />
          </a>
          <a target="_blank" href={infos?.footer?.facebook}>
            <Facebook className="w-6 h-6" />
          </a>
          <a target="_blank" href={infos?.footer?.twitter}>
            <Twitter className="w-6 h-6" />
          </a>
          <a target="_blank" href={infos?.footer?.pinterest}>
            <Pinterest className="w-6 h-6" />
          </a>
        </div>
      </div>
      <AppBar />
    </div>
  );
}

function AppBar() {
  const user = useContext(UserContext);
  return (
    <div className="flex gap-2 lg:gap-4 max-lg:flex max-lg:justify-between items-center text-sm font-semibold px-2 xl:px-8">
      {user ? (
        <div className="lg:hidden">
          <UserDropDown />
        </div>
      ) : null}
      <Link to="/">
        <img src="/images/logo.png" alt="logo" className="w-24 lg:w-28" />
      </Link>
      <NavDrawer />
      <NavBar />
    </div>
  );
}

export default Header;
