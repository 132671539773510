import React from "react";
import { fileUrl } from "../../Tools/APIs";
import parse from 'html-react-parser';

function OfferCard({ cardDetails }) {
  return (
    <div className="rounded-lg group hover:scale-105 transition-all flex flex-col justify-between gap-4 shadow-md hover:shadow-xl text-center">
      <div>
        <img
          src={fileUrl + cardDetails.image}
          alt={"product image"}
          className="rounded-lg w-full aspect-[6/4] object-cover object-center"
        />
      </div>
      <div className="p-2 space-y-2">
        <p className="font-bold break-words">{cardDetails.name}</p>
        <p className="px-2 text-sm break-words">{parse(cardDetails.description)}</p>
        <p className="font-semibold">dicount {cardDetails.discount}</p>
      </div>
    </div>
  );
}

export default OfferCard;
