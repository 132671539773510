import React from "react";
import BrandCard from "../components/cards/BrandCard";
import { useParams } from "react-router-dom";
import { useFETCH, useFilter } from "../Tools/APIs";
import CardSkeleton from "../components/skeletons/CardSkeleton";
import { language } from "../translation/Translate";
import Loader from "../components/Loader";

function Brands() {
  const { id } = useParams();
  const { filter } = useFilter();
  const isSubsection = filter.get("is_subsection");
  const { data, isLoading, isError } = useFETCH(`sections/${id}`);
  const section = useFETCH(`sections/detail/${id}`);

  return section.isLoading || isLoading ? (
    <Loader />
  ) : (
    <div className="space-y-8">
      <h2 className="text-center font-bold text-2xl md:text-3xl">
        {" "}
        {section?.data?.data?.name + " >> "}{" "}
        {isSubsection
          ? language === "ar"
            ? "الأقسام الفرعية "
            : "Sub Sections"
          : language === "ar"
          ? "ماركات"
          : "Brands"}{" "}
      </h2>
      <div className="grid py-4 w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-4 md:gap-6">
        {!isLoading ? (
          data?.data?.length > 0 ? (
            data?.data?.map((brand) => {
              return <BrandCard cardDetails={brand} />;
            })
          ) : (
            <div className="h-[60vh] col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 w-full flex justify-center items-center text-center">
              <p dir="auto" className="w-full text-xl font-bold text-center">
                {language === "en" ? "No brands available." : "لا يوجد ماركات."}
              </p>
            </div>
          )
        ) : (
          <>
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
            <CardSkeleton />
          </>
        )}
      </div>
    </div>
  );
}

export default Brands;
