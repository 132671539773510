import React, { useContext, useEffect } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../App";
import { fileUrl, usePOST } from "../Tools/APIs";
import { content, language } from "../translation/Translate";
import { toast } from "sonner";

function UserDropDown() {
  const user = useContext(UserContext);
  const location = useLocation();
  const { handleSubmit, successfulPost, loading, errors } = usePOST();

  const logout = () => {
    handleSubmit("logout");
  };

  useEffect(() => {
    if (errors.length > 0) {
      toast.dismiss("loading");
      errors.map((e) => toast.error(e));
    }
  }, [errors]);

  useEffect(() => {
    if (loading) {
      toast.loading(language === "ar" ? "يتم تسجيل الخروج" : "Login out", {
        id: "loading",
      });
    }
  }, [loading]);

  useEffect(() => {
    if (successfulPost) {
      toast.dismiss("loading");
      window.location.reload();
    }
  }, [successfulPost]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex items-center gap-1">
        <img
          className="rounded-full w-12 h-12 shadow-lg border-2 border-gray-300"
          src={user.image ? fileUrl + user.image : "/images/UserAvatar.jpg"}
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent dir="auto" className="bg-white space-y-1.5">
        <DropdownMenuLabel>
          {content.user_drop.title}, {user.username}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className={`hover:bg-gray-100 ${
            location.pathname === "/profile" ? "bg-gray-200" : ""
          }`}
        >
          <Link className="w-full h-full" to="/profile">
            {content.user_drop.profile}
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem
          className={`hover:bg-gray-100 ${
            location.pathname === "/orders" ? "bg-gray-200" : ""
          }`}
        >
          <Link className="w-full h-full" to="/orders">
            {content.user_drop.orders}
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem className={`hover:bg-gray-100 `}>
          <p onClick={logout}>{content.user_drop.logout}</p>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default UserDropDown;
