import React from "react";
import { Button } from "../ui/button";
import { Link, useLocation } from "react-router-dom";
import { language } from "../../translation/Translate";
import { fileUrl } from "../../Tools/APIs";

function SectionCard({ cardDetails }) {

  return (
    <div className="rounded-lg group cursor-pointer flex flex-col justify-between gap-4 shadow-md hover:shadow-xl transition-shadow text-center">
      <div>
        <img
          src={fileUrl + cardDetails.image}
          alt={"product image"}
          className="rounded-lg shadow-sm aspect-[6/4] w-full object-cover object-center"
        />
      </div>
      <p className="font-bold pb-2 break-words">{cardDetails.name}</p>
      <Link to={`/sections/brands/${cardDetails.id}`}>
        <Button className="bg-orange-100 text-amber-500 group-hover:bg-amber-500 group-hover:text-white transition-all font-bold w-full p-4 rounded-b-md">
          {language === "ar" ? "عرض القسم" : "View Section"}
        </Button>
      </Link>
    </div>
  );
}

export default SectionCard;
