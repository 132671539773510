import React, { useContext } from "react";
import ImagesSlider from "../components/ImagesSlider";
import { content } from "../translation/Translate";
import parse from "html-react-parser";
import { fileUrl, useFETCH } from "../Tools/APIs";
import VideoSkeleton from "../components/skeletons/VideoSkeleton";
import ImageSkeleton from "../components/skeletons/ImageSkeleton";

function About() {
  const { data, isLoading } = useFETCH("infos");
  const imagesSlider = useFETCH("infos/image-slider");
  
  return (
    <div className="px-4 md:px-12 py-8 space-y-8">
      <h2 className="text-center font-bold text-2xl md:text-3xl">
        {content.about_us.title}
      </h2>
      {isLoading ? (
        <VideoSkeleton />
      ) : (
        data?.about?.video && (
          <div className="relative">
            <video
              key={fileUrl + data?.about?.video}
              className="w-full md:w-2/3 mx-auto"
              controls
            >
              <source src={fileUrl + data?.about?.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )
      )}

      <p className="max-sm:text-sm font-semibold p-4">
        {!isLoading ? (
          parse(data?.about?.description || "")
        ) : (
          <div>
            <div className="animate-pulse bg-gray-100 rounded-lg w-full h-6 mb-2"></div>
            <div className="animate-pulse bg-gray-100 rounded-lg w-full h-6 mb-2"></div>
            <div className="animate-pulse bg-gray-100 rounded-lg w-full h-6 mb-2"></div>
          </div>
        )}
      </p>
      {imagesSlider.isLoading ? (
        <ImageSkeleton />
      ) : (
        <ImagesSlider images={imagesSlider.data?.data?.about} height={true} />
      )}
    </div>
  );
}

export default About;
