import { create } from 'zustand';

const useUser = create((set) => ({
    user: undefined,
    favoritesCount: 0,
    cartCount: 0,
    setUser: (user) => set({ user: user }),
    setFavoritesCount: (count) => set({ favoritesCount: count }),
    setCartCount: (count) => set({ cartCount: count }),
}));

export { useUser };