import React from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { language } from "../translation/Translate";
import { Trash } from "./ui/svgs";

export default function DeleteDialog({ action }) {
  return (
    <Dialog>
      <DialogTrigger id="close-trigger">
        <div className="bg-gray-100 cursor-pointer flex justify-center border group p-2 rounded hover:bg-red-600 transition">
          <Trash style="text-red-500 fill-red-500 w-5 h-5 group-hover:fill-white group-hover:text-white transition" />
        </div>
      </DialogTrigger>
      <DialogContent className="bg-white">
        <DialogHeader>
          <DialogTitle>
            {language === "ar" ? "هل أنت متأكد؟" : "Are you sure?"}
          </DialogTitle>
          <DialogDescription className="text-center">
            {language === "ar"
              ? "لا يمكنك التراجع بعد ذلك"
              : "You can't undo this"}
          </DialogDescription>
        </DialogHeader>
        <div className="flex w-full gap-4 items-center justify-center">
            <button
              onClick={action}
              className={`p-2 w-full rounded-md bg-red-500 border border-red-500 text-white transition-colors hover:bg-white hover:text-red-500`}
            >
              {language === "ar" ? "تأكيد" : "Confirm"}
            </button>
          <DialogClose className="p-2 w-full border border-amber-500 rounded-md bg-white text-amber-500">
            <button
              onClick={() => document.getElementById("close-trigger")?.click()}
              
            >
              {language === "ar" ? "الغاء" : "Cancel"}
            </button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
}
