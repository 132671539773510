import React, { createContext, useEffect, useState } from "react";
import "./index.css";
import "./App.css";
import { Outlet, Route, Routes } from "react-router-dom";
import About from "./routes/About";
import ContactUs from "./routes/ContactUs";
import Branches from "./routes/Branches";
import Products from "./routes/Products";
import Sections from "./routes/Sections";
import Offers from "./routes/Offers";
import Branch from "./routes/Branch";
import Brands from "./routes/Brands";
import Login from "./routes/Login";
import Signup from "./routes/Signup";
import Chat from "./routes/Chat";
import Cart from "./routes/Cart";
import Favorites from "./routes/Favorites";
import Profile from "./routes/Profile";
import Orders from "./routes/Orders";
import Logout from "./routes/Logout";
import Checkout from "./routes/Checkout";
import Product from "./routes/Product";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useLocation } from "react-router-dom";
import { MessageCircleMore } from "lucide-react";
import { Link } from "react-router-dom";
import Home from "./routes/Home";
import { direction } from "./translation/Translate";
import ForgetPassword from "./routes/ForgetPassword";
import { useFETCH } from "./Tools/APIs";
import Loader from "./components/Loader";
import { useUser } from "./lib/store/user";

export const UserContext = createContext(undefined);

function App() {
  const location = useLocation();
  const { data, isLoading } = useFETCH("profile", true);
  const infos = useFETCH("infos");
  const [user, setUser] = useState();
  const { setFavoritesCount, setCartCount } = useUser();

  useEffect(() => {
    const handleNavigate = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    handleNavigate();
  }, [location.pathname]);

  useEffect(() => {
    if (data) {
      setUser(data.data);
      setCartCount(data.data?.cart_products_count);
      setFavoritesCount(data.data?.favorite_products_count);
    }
  }, [data]);

  return (
    <div className="bg-gray-50  relative z-0">
      <UserContext.Provider value={user}>
        {isLoading || infos.isLoading ? (
          <>
            <Loader />
          </>
        ) : (
          <div
            dir={direction}
            className="min-h-screen flex flex-col justify-between"
          >
            <Header infos={infos.data} />
            <Routes>
              <Route path="chat" element={<Chat />} />
              <Route path="cart" element={<Cart />}>
                <Route path="checkout" element={<Checkout />} />
              </Route>
              <Route path="favorites" element={<Favorites />} />
              <Route path="" element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="contactUs" element={<ContactUs />} />
              <Route path="branches" element={<Branches />}>
                <Route path="preview/:id" element={<Branch />} />
              </Route>
              <Route path="products" element={(location.pathname.includes("/preview")) ? <Outlet /> :<Products />}>
                <Route
                  path="preview/:productId/:brandId"
                  element={<Product />}
                />
              </Route>
              <Route path="products/brand/:brandId" element={<Products />} />
              <Route path="sections" element={<Sections />}>
                <Route path="brands/:id" element={<Brands />} />
              </Route>
              <Route path="offers" element={<Offers />} />
              <Route
                path="login"
                element={
                  location.pathname === "/login" ? <Login /> : <Outlet />
                }
              >
                <Route path="change-password" element={<ForgetPassword />} />
              </Route>
              <Route path="signup" element={<Signup />} />
              <Route path="profile" element={<Profile />} />
              <Route path="orders" element={<Orders />} />
              <Route path="logout" element={<Logout />} />
            </Routes>
            <Link
              to="/chat"
              className={`fixed right-4 bottom-8 shadow-xl hover:scale-110 hover:shadow-2xl transition-all z-20 bg-white p-2.5 rounded-full cursor-pointer ${
                location.pathname === "/chat" ||
                location.pathname.includes("/cart") ||
                !data?.data
                  ? "hidden"
                  : ""
              }`}
            >
              <MessageCircleMore className="w-8 h-8 md:w-10 md:h-10 text-amber-500" />
            </Link>
            {location.pathname !== "/chat" && <Footer infos={infos.data} />}
          </div>
        )}
      </UserContext.Provider>
    </div>
  );
}

export default App;
