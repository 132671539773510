import SearchBar from "../components/home/SearchBar";
import CardsSlider from "../components/CardsSlider";
import ContactUs from "../components/contactUs/ContactUsComponent";
import ImagesSlider from "../components/ImagesSlider";
import ProductCard from "../components/cards/ProductCard";
import SectionCard from "../components/cards/SectionCard";
import BranchCard from "../components/cards/BranchCard";
import { content } from "../translation/Translate";
import { useFETCH } from "../Tools/APIs";
import CardSkeleton from "../components/skeletons/CardSkeleton";
import ImageSkeleton from "../components/skeletons/ImageSkeleton";

export default function Home() {
  const infos = useFETCH("infos");
  const imagesSlider = useFETCH("infos/image-slider");
  const specialSectionsData = useFETCH(`sections?is_special=1`);
  const specialProductsData = useFETCH(`all/products?is_special=1`);
  const latestProducts = useFETCH(`all/products`);
  const mostOrdered = useFETCH(`all/products/most/ordered/products`);
  const specialBranchesData = useFETCH(`branches?is_special=1`);
  const productsCards = specialProductsData.isLoading
    ? Array.from({ length: 6 }).map(() => {
        return (
          <div className="px-4 h-full">
            <CardSkeleton />
          </div>
        );
      })
    : specialProductsData.data?.data?.data?.map((product) => {
        return (
          <div className="px-4 h-full">
            <ProductCard product={product} />
          </div>
        );
      });
  const sectionsCards = specialSectionsData.isLoading
    ? Array.from({ length: 6 }).map(() => {
        return (
          <div className="px-4 h-full">
            <CardSkeleton />
          </div>
        );
      })
    : specialSectionsData.data?.data?.map((section) => {
        return (
          <div className="px-4 h-full">
            <SectionCard cardDetails={section} />
          </div>
        );
      });
  const branchesCards = specialBranchesData.isLoading
    ? Array.from({ length: 6 }).map(() => {
        return (
          <div className="px-4 h-full">
            <CardSkeleton />
          </div>
        );
      })
    : specialBranchesData.data?.data?.data?.map((branch) => {
        return (
          <div className="px-4 h-full">
            <BranchCard cardDetails={branch} />
          </div>
        );
      });
  const latestProductsCards = latestProducts.isLoading
    ? Array.from({ length: 6 }).map(() => {
        return (
          <div className="px-4 h-full">
            <CardSkeleton />
          </div>
        );
      })
    : latestProducts.data?.data?.data?.map((product) => {
        return (
          <div className="px-4 h-full">
            <ProductCard product={product} />
          </div>
        );
      });
  const mostOrderedCards = mostOrdered.isLoading
    ? Array.from({ length: 6 }).map(() => {
        return (
          <div className="px-4 h-full">
            <CardSkeleton />
          </div>
        );
      })
    : mostOrdered.data?.data?.map((product) => {
        return (
          <div className="px-4 h-full">
            <ProductCard product={product} />
          </div>
        );
      });
    
  return (
    <div className="px-4 md:px-12 py-8 space-y-8">
      {imagesSlider.isLoading ? (
        <ImageSkeleton />
      ) : (
        <ImagesSlider images={imagesSlider.data?.data?.home} height={true} />
      )}
      <SearchBar searchInfos={infos.data?.home?.search} />
      <CardsSlider
        link={"/sections"}
        cards={sectionsCards}
        title={content.home.top_sections}
      />
      <CardsSlider
        link={"/products"}
        cards={productsCards}
        title={content.home.top_products}
      />
      <CardsSlider
        link={"/products"}
        cards={mostOrderedCards}
        title={content.home.most_requested}
      />
      <CardsSlider
        link={"/products"}
        cards={latestProductsCards}
        title={content.home.latest_products}
      />
      <CardsSlider
        link={"/branches"}
        cards={branchesCards}
        title={content.home.branches}
      />
      <ContactUs infos={infos.data} />
    </div>
  );
}
