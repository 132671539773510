import { create } from "zustand";

const useMessages = create((set) => ({
    messages: [],
    setMessages: (messages) => set({ messages: messages }),
    addMessage: (message) =>
        set((state) => ({ messages: [message, ...state.messages] })),
    removeMessage: (message) =>
        set((state) => ({
            messages: state.messages.filter((m) => m.id !== message.id),
        })),
    updateMessage: (message) =>
        set((state) => ({
            messages: state.messages.map((m) => {
                if (m.loading && m.content === message.content) {
                    return message;
                }
                return m;
            }),
        })),
}));

export { useMessages };
