import React, { useContext } from "react";
import ProductCard from "../components/cards/ProductCard";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { content, language } from "../translation/Translate";
import { useParams } from "react-router-dom";
import { useFETCH } from "../Tools/APIs";
import CardSkeleton from "../components/skeletons/CardSkeleton";
import { UserContext } from "../App";

function Products() {
  const user = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const { id, brandId } = useParams();
  const sortBy = searchParams.get("sort_by");
  const search = searchParams?.get("search");
  const startPrice = searchParams?.get("start_price");
  const endPrice = searchParams?.get("end_price");
  let url;
  if (brandId) {
    url = `${brandId}/products`;
  } else if (search || startPrice || endPrice) {
    if (sortBy) {
      if (sortBy === "name") {
        url = `all/products?search=${search}&sort_by=${sortBy}`;
      } else if (sortBy === "price") {
        if (startPrice && endPrice) {
          url = `all/products?start_price=${startPrice}&end_price=${endPrice}&sort_by=${sortBy}`;
        } else if (startPrice) {
          url = `all/products?start_price=${startPrice}&sort_by=${sortBy}`;
        } else if (endPrice) {
          url = `all/products?end_price=${endPrice}&sort_by=${sortBy}`;
        }
      }
    } else {
      url = `all/products?search=${search}`;
    }
  } else {
    url = `all/products`;
  }
  const { data, isLoading } = useFETCH(url, user ? true : false);

    return (
      <div className="px-2 sm:px-4 md:px-8 py-8 space-y-8">
        <h2 className="text-center font-bold text-2xl md:text-3xl">
          {brandId ? language === "ar" ? `منتجات ${data?.data?.data?.at(0)?.name || ""}` : `${data?.data?.data?.at(0)?.name || ""} Products` : content.products.title}
        </h2>
        <div className="grid gap-4 py-4 w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-6">
          {!isLoading ? (
            data?.data?.data?.length > 0 ? (
              data?.data?.data?.map((product) => {
                return <ProductCard product={product} />;
              })
            ) : (
              <div className="h-[60vh] col-span-1 sm:col-span-2 md:col-span-3 lg:col-span-4 w-full flex justify-center items-center text-center">
                <p dir="auto" className="w-full text-xl font-bold text-center">
                  {language === "en"
                    ? "No products available."
                    : "لا يوجد منتجات."}
                </p>
              </div>
            )
          ) : (
            <>
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </>
          )}
        </div>
      </div>
    );
}

export default Products;
