import React from "react";
import { Facebook, Instagram, Mail, Phone, Twitter } from "lucide-react";
import { Whatsapp } from "../ui/svgs";
import ContactUsForm from "./ContactUsForm";
import { content } from "../../translation/Translate";

function ContactUs({ infos }) {
  return (
    <div className="flex flex-col space-y-8 py-4 justify-center items-center w-full">
      <div className="w-full lg:w-1/2 space-y-8 text-center">
        <h4 className="text-2xl md:text-3xl font-bold">
          {infos?.contact_us.headline}
        </h4>
        <ContactUsForm />
      </div>
      <div className="flex w-full lg:w-2/3 flex-col gap-8 items-center">
        <div className="sm:flex w-full py-8 justify-center items-center border-b-4 border-b-gray-300">
          <div className="basis-1/3 flex px-2 max-md:text-sm flex-col justify-center items-centers text-center max-sm:border-2 max-sm:p-3 max-sm:my-2 max-sm:rounded-md border-gray-300 space-y-2">
            <p className="flex items-center justify-center gap-2">
              <Mail className="rounded-full  bg-black p-1 text-white" />
              <span>{content.home.email}</span>
            </p>
            <a href={`mailto:${infos?.contact_us?.email}`}>
              {infos?.contact_us?.email}
            </a>
          </div>
          <div className="basis-1/3 flex px-2 max-md:text-sm flex-col justify-center items-centers text-center max-sm:border-2 max-sm:p-3 max-sm:my-2 max-sm:rounded-md border-gray-300 space-y-2 sm:border-l-4 sm:border-r-4 border-gray-300">
            <p className="flex items-center justify-center gap-2">
              <Phone className="rounded-full bg-black p-1 text-white" />
              <span>{content.home.phone}</span>
            </p>
            <a href={`tel:${infos?.contact_us?.phone1}`}>
              {infos?.contact_us?.phone1}
            </a>
          </div>
          <div className="basis-1/3 flex px-2 max-md:text-sm flex-col justify-center items-centers text-center max-sm:border-2 max-sm:p-3 max-sm:my-2 max-sm:rounded-md border-gray-300 space-y-2">
            <p className="flex items-center justify-center gap-2">
              <Phone className="rounded-full bg-black p-1 text-white" />
              <span>{content.home.phone}</span>
            </p>
            <a href={`mailto:${infos?.contact_us?.phone2}`}>
              {infos?.contact_us?.phone2}
            </a>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <a target="_blank" href={infos?.footer?.instagram}>
            <div className="cursor-pointer">
              <Instagram className="w-6 h-6 text-gray-300 md:w-8 md:h-8" />
            </div>
          </a>
          <a target="_blank" href={infos?.footer?.facebook}>
            <div className="cursor-pointer">
              <Facebook className="w-6 h-6 text-gray-300 md:w-8 md:h-8" />
            </div>
          </a>
          <a target="_blank" href={infos?.footer?.twitter}>
            <div className="cursor-pointer">
              <Twitter className="w-6 h-6 text-gray-300 md:w-8 md:h-8" />
            </div>
          </a>
          <a target="_blank" href={infos?.footer?.whatsapp}>
            <div className="cursor-pointer">
              <Whatsapp style="w-6 h-6 text-gray-300 md:w-8 md:h-8" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
