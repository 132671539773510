import React, { useEffect, useState } from "react";
import { usePOST } from "../Tools/APIs";
import { toast } from "sonner";
import { content, language } from "../translation/Translate";

function ResendCode({ email }) {
  const [show, setShow] = useState(false);
  const [time, setTime] = useState(60);
  const { handleSubmit, setFormData, successfulPost, dataPlayer, formData, errors } = usePOST({email});

  const resendCode = async () => {
    setShow(false);
    setTime(60);
    await handleSubmit("send/verification-code", false, true, false);
  };

  useEffect(() => {
    if(successfulPost) {
      toast.success("تم إرسال الرمز")
    }
  }, [successfulPost])

  useEffect(() => {
    if(errors.length > 0) {
      errors.map((e) => toast.error(e))
    }
  }, [errors])


  useEffect(() => {
    let timer;
    if (time > 0) {
      timer = setTimeout(() => setTime(time - 1), 1000);
    } else {
      setShow(true);
    }
    return () => clearTimeout(timer);
  }, [time]);

  return (
    <p className="text-center text-sm">
      {content.confirm_account.didnt_recieve}
      {show ? (
        <span
          onClick={resendCode}
          className="underline text-amber-500 cursor-pointer"
        >
          {language === "ar" ? "إعادة الإرسال" : "Resend"}
        </span>
      ) : (
        <span className="text-amber-500">
          {language === "ar" ? `إعادة الإرسال في ${time} ثانية` : `Resending in ${time} seconds`}
        </span>
      )}
    </p>
  );
}

export default ResendCode;
