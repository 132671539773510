import { Link } from "react-router-dom";
import { language } from "../../translation/Translate";
import LocationAddress from "../LocationAddress";
export default function Order({ order }) {
  return (
    <div className="flex flex-col w-full h-full gap-4 p-4 max-sm:p-6 rounded-lg items-start justify-start bg-white shadow-lg">
      <p className="text-lg font-semibold text-gray-800">
        {language === "ar" ? "رقم الطلب" : "Order number"} #{order?.id}
      </p>
      <p className="text-md text-gray-600">
        {language === "ar" ? "السعر: " : "Price: "}
        <span className="text-lg font-bold">
          {order?.total_price} {language === "ar" ? "د.ع" : "IQD"}
        </span>
      </p>
      <div
        className={`px-3 py-1 rounded-full text-sm font-semibold ${
          order?.status === "completed" || order?.status === "مكتمل"
            ? "bg-green-100 text-green-600"
            : order?.status === "pending" || order?.status === "معلق"
            ? "bg-amber-100 text-amber-600"
            : order?.status === "preparing" || order?.status === "قيد التحضير"
            ? "bg-cyan-100 text-cyan-600"
            : "bg-red-100 text-red-600"
        }`}
      >
        {order?.status}
      </div>
      <div>
        <p>{language === "ar" ? "الموقع: " : "Location: "}</p>
        <LocationAddress lat={order?.location?.latitude} lng={order?.location?.longitude} />
      </div>
      {order?.products && order?.products.length > 0 && (
        <div className="w-full space-y-2">
          <h4 className="text-lg font-semibold text-gray-800 mb-2 border-gray-300">
            {language === "ar" ? "المنتجات:" : "Products:"}
          </h4>
          <div className="w-full bg-gray-50 rounded-lg shadow-sm">
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
              {order.products.map((product) => (
                <Link
                  to={`/products/preview/${product.id}/${product.brand.id}`}
                  key={product.id}
                  className="border rounded-lg p-2 shadow-md flex flex-col items-center transition-transform transform hover:scale-105 hover:shadow-lg"
                >
                  <h3 className="font-semibold text-lg text-gray-800">
                    {product.name}
                  </h3>
                  <p className="text-md mt-2 text-gray-600">
                    {language === "ar" ? "السعر:" : "Price:"}{" "}
                    <span className="font-semibold">
                      {product.price} {language === "ar" ? "د.ع" : "IQD"}
                    </span>
                  </p>
                  <p className="text-md mt-2 text-gray-600">
                    {language === "ar" ? "الكمية:" : "Count:"}{" "}
                    <span className="font-semibold">{product.count}</span>
                  </p>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
