import React from "react";
import { Button } from "../ui/button";
import { Link, useParams } from "react-router-dom";
import { language } from "../../translation/Translate";
import { fileUrl, useFilter } from "../../Tools/APIs";

function BrandCard({ cardDetails }) {
  const { id } = useParams();
  const { filter } = useFilter();
  const isSubsection = filter.get("is_subsection");
  console.log(isSubsection)
  return (
    <div className="rounded-lg group flex flex-col justify-between gap-4 h-full cursor-pointer shadow-md hover:shadow-xl transition-shadow text-center">
      <div className="relative">
        <img
          src={fileUrl + cardDetails.image}
          alt={"product image"}
          className="rounded-lg shadow-sm w-full aspect-[6/4] object-cover object-center"
        />
      </div>
      <p className="font-bold py-2 break-words">{cardDetails.name}</p>
      <Link to={cardDetails.has_products || isSubsection ? id ? `/products/brand/${cardDetails.id}` : "/products" : `/sections/brands/${cardDetails.id}?is_subsection=true`}>
        <Button className="bg-orange-100 text-amber-500 group-hover:bg-amber-500 group-hover:text-white transition-all font-bold w-full p-2 sm:p-4 rounded-b-md">
          {cardDetails.has_products || isSubsection ? language === "ar" ? "عرض المنتجات" : "View Products" : language === "ar" ? "عرض الأقسام" : "View Sections"}
        </Button>
      </Link>
    </div>
  );
}

export default BrandCard;
