import axios from "axios";
import { MapPin } from "lucide-react";
import { useEffect, useState } from "react";
import { language } from "../translation/Translate";

export default function LocationAddress({ lat, lng }) {
  const [address, setAddress] = useState("");

  const getAddress = async () => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&zoom=18&addressdetails=1&accept-language=${language}`
      );
      const data = response.data;
      setAddress(data.display_name || "Unknown");
    } catch (error) {
      console.error(error);
      setAddress("Unknown");
    }
  };

  useEffect(() => {
    getAddress();
  }, []);

  return (
    <div
      className={`flex items-center cursor-pointer w-full gap-4 font-semibold px-1 md:px-4 py-4 md:py-8 rounded bg-white shadow-md rounded transition-colors`}
    >
      <div className="bg-amber-500 rounded-full p-1">
        <MapPin className="text-white" />
      </div>
      {address ? (
        <p className="text-sm">{address}</p>
      ) : (
        <div className="animate-pulse bg-gray-100 rounded-lg w-full h-6 mb-2"></div>
      )}
    </div>
  );
}
