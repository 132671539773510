import React, { useEffect, useRef, useState } from "react";
import { useMessages } from "../lib/store/messages";
import ListMessages from "../components/chat/ListMessages";
import { useCookies } from "react-cookie";
import { useFETCH } from "../Tools/APIs";
import ChatInput from "../components/chat/ChatInput";
import { language } from "../translation/Translate";

function Chat() {
  const { setMessages, messages } = useMessages();
  const [page, setPage] = useState(1);
  const prevPageRef = useRef(0);
  const [cookies] = useCookies([
    "token",
    {
      doNotParse: true,
    },
  ]);
  const { data, isLoading } = useFETCH(
    `chat/users/admin/messages?page=${page}`,
    cookies.token
  );

  useEffect(() => {
    prevPageRef.current = page;
  }, [page]);

  useEffect(() => {
    if (
      data &&
      data?.data?.last_page >= page &&
      data?.data?.current_page === page && 
      data?.data?.data?.at(0)?.id !== messages?.at(0)?.id
    )
      setMessages([...messages, ...data?.data?.data]);
  }, [data]);

  return (
    <div className="h-screen px-1 md:px-12 py-4">
      <div className=" h-full flex flex-col justify-between">
        <div className="bg-white shadow-sm p-2 w-full flex items-center gap-4">
          <img
            src="/images/admin.png"
            className="rounded-full w-12 h-12 md:w-14 md:h-14"
          />
          <p className="text-lg md:text-2xl font-bold">{language === "ar" ? "الأدمن" : "Admin"}</p>
        </div>

        <ListMessages
          isLoading={isLoading}
          chat={data?.data?.data}
          page={page}
          setPage={setPage}
          last_page={data?.data?.last_page}
        />
        <ChatInput />
      </div>
    </div>
  );
}

export default Chat;
