export function Pinterest({ style }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={style}
    >
      <path
        d="M9.1659 0.320435C4.37168 0.320435 0.484375 4.20601 0.484375 8.99808C0.484375 12.5505 2.62375 15.6032 5.68166 16.9474C5.65841 16.3431 5.67779 15.6148 5.83281 14.9562C5.99947 14.2512 6.94901 10.2261 6.94901 10.2261C6.94901 10.2261 6.66996 9.67215 6.66996 8.85475C6.66996 7.56859 7.41409 6.60786 8.34425 6.60786C9.13489 6.60786 9.51471 7.20057 9.51471 7.9095C9.51471 8.70366 9.00699 9.88909 8.74732 10.9893C8.53029 11.9113 9.20853 12.659 10.1193 12.659C11.7626 12.659 12.871 10.5477 12.871 8.04509C12.871 6.14298 11.5882 4.72124 9.25891 4.72124C6.62733 4.72124 4.98404 6.68533 4.98404 8.87799C4.98404 9.63341 5.20883 10.168 5.55764 10.5787C5.71654 10.7685 5.7398 10.846 5.68166 11.0629C5.63903 11.2217 5.54601 11.6053 5.50338 11.7602C5.44524 11.981 5.26696 12.0585 5.0693 11.9771C3.85622 11.4813 3.29037 10.1564 3.29037 8.66105C3.29037 6.19722 5.37161 3.24139 9.49533 3.24139C12.809 3.24139 14.991 5.63937 14.991 8.21167C14.991 11.6169 13.0958 14.1582 10.3053 14.1582C9.36743 14.1582 8.48765 13.6507 8.18535 13.0774C8.18535 13.0774 7.68151 15.0763 7.57299 15.4637C7.39084 16.1339 7.0304 16.8002 6.70096 17.3232C7.50009 17.5588 8.32885 17.6789 9.16202 17.6796C13.9562 17.6796 17.8435 13.794 17.8435 9.00196C17.8435 4.20988 13.9601 0.320435 9.1659 0.320435Z"
        fill="white"
      />
    </svg>
  );
}

export function Play({ style }) {
  return (
    <svg
      width="62"
      height="56"
      viewBox="0 0 62 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={style}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.33317 0.25C4.69767 0.25 3.12915 0.8997 1.97268 2.05617C0.816204 3.21265 0.166504 4.78116 0.166504 6.41667V49.5833C0.166504 51.2188 0.816204 52.7873 1.97268 53.9438C3.12915 55.1003 4.69767 55.75 6.33317 55.75H55.6665C57.302 55.75 58.8705 55.1003 60.027 53.9438C61.1835 52.7873 61.8332 51.2188 61.8332 49.5833V6.41667C61.8332 4.78116 61.1835 3.21265 60.027 2.05617C58.8705 0.8997 57.302 0.25 55.6665 0.25H6.33317ZM20.5936 17.6092C20.662 17.0197 20.8673 16.4544 21.193 15.9583C21.5187 15.4622 21.9558 15.0491 22.4695 14.752C22.9832 14.4549 23.5592 14.2819 24.1516 14.2469C24.744 14.2119 25.3364 14.3159 25.8815 14.5505C27.4355 15.2165 30.7162 16.715 34.8725 19.1138C37.7935 20.7833 40.6144 22.622 43.3208 24.6207C43.7967 24.9747 44.1832 25.4351 44.4494 25.9651C44.7157 26.4951 44.8543 27.08 44.8543 27.6732C44.8543 28.2663 44.7157 28.8512 44.4494 29.3812C44.1832 29.9112 43.7967 30.3716 43.3208 30.7257C40.6143 32.7222 37.7934 34.5589 34.8725 36.2263C31.9671 37.9234 28.965 39.4492 25.8815 40.7958C25.3365 41.0311 24.7439 41.1356 24.1513 41.1009C23.5586 41.0662 22.9823 40.8934 22.4684 40.5961C21.9546 40.2989 21.5174 39.8855 21.1918 39.3891C20.8663 38.8927 20.6614 38.3269 20.5936 37.7372C20.2236 34.3954 20.0425 31.0354 20.0509 27.6732C20.0509 22.8909 20.3963 19.2896 20.5936 17.6092Z"
        fill="white"
      />
    </svg>
  );
}

export function Whatsapp({ style }) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={style}
    >
      <path
        d="M28.1551 3.11826C38.5707 5.12125 45.6479 14.1436 45.2918 24.8685C44.9647 34.7589 36.9638 43.3806 26.8865 44.6158C22.8395 45.1272 18.7307 44.4627 15.0511 42.7018C14.4625 42.4464 13.8085 42.3825 13.1816 42.5193C10.1638 43.2426 7.17042 44.0639 4.16816 44.845C3.83878 44.9296 3.50049 44.9875 3.01978 45.0876C3.92112 41.7894 4.76238 38.6246 5.6615 35.4733C5.89073 34.6765 5.82619 34.04 5.45898 33.2588C1.95374 25.8144 2.25864 18.5101 7.03466 11.7601C11.8218 4.98995 18.5786 2.10119 26.8865 2.94689C27.2782 2.98473 27.6677 3.04037 28.1551 3.11826ZM41.317 27.6082C41.9134 25.169 41.9847 22.6964 41.4016 20.2683C39.6478 12.9507 35.0944 8.20809 27.6922 6.70807C20.4302 5.23921 14.3322 7.68731 10.0302 13.6918C5.72159 19.7053 5.62812 26.1549 9.18454 32.6157C9.64078 33.4458 9.76318 34.129 9.48499 35.0148C8.97757 36.635 8.58364 38.2908 8.08735 40.1291C10.146 39.595 11.9509 39.0831 13.7803 38.6825C14.2988 38.569 14.9776 38.6291 15.4317 38.8783C25.8828 44.6314 38.3058 39.3235 41.317 27.6082Z"
        fill="#C0C0C0"
      />
      <path
        d="M19.1438 15.3945C19.5422 16.3448 19.8115 17.264 20.2789 18.0718C20.9465 19.2247 20.744 20.1928 19.8226 21.0051C18.8323 21.8775 18.9791 22.6186 19.6891 23.6201C21.3249 25.9258 23.379 27.655 25.9718 28.7878C26.684 29.0994 27.2248 29.1528 27.6855 28.4384C27.8769 28.1447 28.1439 27.9043 28.3665 27.6283C29.664 26.0126 29.2567 26.0259 31.3131 26.9184C31.9608 27.201 32.6084 27.5015 33.2071 27.8709C33.808 28.2381 34.7204 28.6165 34.8362 29.1395C35.0988 30.2968 34.7293 31.4718 33.7634 32.3309C31.983 33.9155 29.9355 34.1781 27.6988 33.5594C22.8605 32.2241 19.1394 29.322 16.2885 25.3026C15.2825 23.885 14.3834 22.3115 13.8181 20.6779C13.1326 18.6883 13.6178 16.7699 15.0332 15.0851C15.8678 14.0948 16.8804 13.8722 17.9821 14.1371C18.425 14.2439 18.7343 14.9026 19.1438 15.3945Z"
        fill="#C0C0C0"
      />
    </svg>
  );
}

export function Arrow({style}) {
  return (
    <svg
      width="115"
      height="167"
      viewBox="0 0 115 167"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={style}
    >
      <path
        d="M86.1305 163.633C93.9735 161.582 104.473 158.59 111.767 155.273M111.767 155.273C112.608 154.891 108.005 149.712 107.772 149.491C102.68 144.642 97.1086 140.608 91.3426 136.618M111.767 155.273C67.7397 156.794 31.8274 130.448 26.4377 99.5063C25.9034 96.4397 25.3252 93.1013 25.0466 89.726M25.0466 89.726C24.6029 84.3506 24.919 78.8814 27.3828 74.2659C29.6567 70.0064 34.4133 65.4155 39.4638 65.2966C48.8982 65.0749 53.3885 76.7581 48.0715 84.1376C43.3992 90.6225 31.9764 93.5342 25.0466 89.726ZM25.0466 89.726C24.9004 89.6456 24.7563 89.5623 24.6142 89.476C-9.6763 68.6362 1.30911 22.7543 27.5618 3.03167"
        stroke="url(#paint0_linear_5_74)"
        stroke-width="5"
        stroke-miterlimit="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5_74"
          x1="78.7548"
          y1="16.0209"
          x2="44.0126"
          y2="152.946"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFE491" />
          <stop offset="1" stop-color="#FFA200" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function Trash({ style }) {
  return (
    <svg
      className={style}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.91457 3.86949H14.0686C14.0686 3.31863 13.8498 2.79032 13.4603 2.4008C13.0708 2.01128 12.5425 1.79245 11.9916 1.79245C11.4407 1.79245 10.9124 2.01128 10.5229 2.4008C10.1334 2.79032 9.91457 3.31863 9.91457 3.86949ZM8.52987 3.86949C8.52987 2.95138 8.89459 2.07088 9.54379 1.42168C10.193 0.772478 11.0735 0.407761 11.9916 0.407761C12.9097 0.407761 13.7902 0.772478 14.4394 1.42168C15.0886 2.07088 15.4533 2.95138 15.4533 3.86949H22.3768C22.5604 3.86949 22.7365 3.94244 22.8664 4.07228C22.9962 4.20212 23.0691 4.37822 23.0691 4.56184C23.0691 4.74546 22.9962 4.92156 22.8664 5.0514C22.7365 5.18124 22.5604 5.25418 22.3768 5.25418H20.9173L19.264 19.5774C19.1471 20.5899 18.6621 21.5241 17.9013 22.2022C17.1404 22.8804 16.1568 23.2551 15.1376 23.2552H8.84558C7.82639 23.2551 6.84278 22.8804 6.08192 22.2022C5.32107 21.5241 4.83607 20.5899 4.7192 19.5774L3.06587 5.25418H1.60641C1.42279 5.25418 1.24669 5.18124 1.11685 5.0514C0.987006 4.92156 0.914062 4.74546 0.914062 4.56184C0.914062 4.37822 0.987006 4.20212 1.11685 4.07228C1.24669 3.94244 1.42279 3.86949 1.60641 3.86949H8.52987ZM10.6069 9.40826C10.6069 9.22464 10.534 9.04854 10.4041 8.9187C10.2743 8.78886 10.0982 8.71592 9.91457 8.71592C9.73094 8.71592 9.55484 8.78886 9.425 8.9187C9.29516 9.04854 9.22222 9.22464 9.22222 9.40826V17.7164C9.22222 17.9 9.29516 18.0761 9.425 18.206C9.55484 18.3358 9.73094 18.4088 9.91457 18.4088C10.0982 18.4088 10.2743 18.3358 10.4041 18.206C10.534 18.0761 10.6069 17.9 10.6069 17.7164V9.40826ZM14.0686 8.71592C13.885 8.71592 13.7089 8.78886 13.5791 8.9187C13.4492 9.04854 13.3763 9.22464 13.3763 9.40826V17.7164C13.3763 17.9 13.4492 18.0761 13.5791 18.206C13.7089 18.3358 13.885 18.4088 14.0686 18.4088C14.2523 18.4088 14.4284 18.3358 14.5582 18.206C14.688 18.0761 14.761 17.9 14.761 17.7164V9.40826C14.761 9.22464 14.688 9.04854 14.5582 8.9187C14.4284 8.78886 14.2523 8.71592 14.0686 8.71592Z"
      />
    </svg>
  );
}
