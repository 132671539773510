import React, { useContext } from "react";
import { language } from "../../translation/Translate";
import { UserContext } from "../../App";
import { Check, CheckCheck, Loader } from "lucide-react";
import { fileUrl } from "../../Tools/APIs";

function Message({ message, isSameUser }) {
  const user = useContext(UserContext);
  return (
    <div
      className={`w-full flex ${
        message.sender_id ? "justify-end" : "justify-start"
      }`}
    >
      <div
        className={`flex ${
          message.sender_id && "flex-row-reverse"
        } relative items-start gap-2`}
      >
        <img
          src={message.sender_id ? fileUrl + user.image : "/images/admin.png"}
          className={`${isSameUser && "invisible"} rounded-full w-10 h-10`}
        />
        <div
          className={`${
            !isSameUser && "gap-2"
          } flex-1 flex flex-col justify-start items-start`}
        >
          <p className={`${isSameUser && " hidden"} ${message.sender_id && "self-end"} font-semibold text-sm`}>
            {message.sender_id ? user.username : "admin"}
          </p>
          <div
            className={`rounded-xl flex ${message.sender_id ? "justify-end" : "justify-start"} min-w-48 ${
              message.sender_id
                ? language === "en"
                  ? "rounded-tr-none"
                  : "rounded-tl-none"
                : language === "ar"
                ? "rounded-tr-none "
                : "rounded-tl-none"
            } p-4 ${message.sender_id ? "bg-amber-200" : "bg-gray-200"}`}
          >
            <p className={`text-sm w-fit max-w-80 break-words font-medium`}>
              {message.content}
            </p>
          </div>
        </div>
        {message.sender_id && (
          <div
            className={`absolute bottom-2 ${
              language === "ar" ? "right-2" : "left-2"
            } w-8`}
          >
            {message.loading ? (
              <Loader className="w-4 animate-spin text-blue-700" />
            ) : message.read_at ? (
              <CheckCheck className="w-4 text-blue-700" />
            ) : (
              <Check className="w-4 text-blue-700" />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Message;
