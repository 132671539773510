import React from "react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { content } from "../../translation/Translate";
import { usePOST } from "../../Tools/APIs"

function ChangePassword() {
  const { handleSubmit, handleChangeInput, formData } = usePOST();

  const submit = () => {
    if(formData.old_password && formData.new_password && formData.password_confirmation)
      handleSubmit("change-password");
  }
  return (
    <>
      <Input
        className="h-full w-2/3 p-3 bg-gray-100"
        type="password"
        name="old_password"
        onChange={(e) => handleChangeInput(e)}
        placeholder={"كلمة المرور القديمة"}
      />
      <Input
        className="h-full w-2/3 p-3 bg-gray-100"
        type="password"
        name="new_password"
        onChange={(e) => handleChangeInput(e)}
        placeholder={"كلمة المرور الجديدة"}
      />
      <Input
        className="h-full w-2/3 p-3 bg-gray-100"
        type="password"
        name="password_confirmation"
        onChange={(e) => handleChangeInput(e)}
        placeholder={"تأكيد كلمة المرور"}
      />
      <Button
        onClick={submit}
        className={`bg-amber-500 text-white w-1/2 h-full p-2 border border-amber-500 rounded-md hover:text-amber-500 hover:bg-white transition`}
      >
        {content.profile.submit}
      </Button>
    </>
  );
}

export default ChangePassword;
