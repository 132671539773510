import React from "react";
import { Button } from "./button";
import { Loader } from "lucide-react";

export default function LoadingButton() {
  return (
    <Button className="bg-white border flex justify-center border-amber-500 p-2 px-4 rounded-md">
      <Loader className="w-4  animate-spin text-amber-500" />
    </Button>
  );
}
